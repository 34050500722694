import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import zoomLogo from './../../Assets/zoom-logo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300,
    display: 'inline-block',
    margin: '0px 20px 20px 0px',
    textAlign: 'center',
    paddingTop: 40
  },
  title: {
    fontSize: 14,
  },
  header: {
    margin: '0px !important'
  },
  media: {
    margin: '0 auto 10px',
    width: '50px',
    height: '50px !important'
  },
  button: {
    borderRadius: 20,
    padding: '5px 10px',
    width: 140,
    marginBottom: 20,
    border: '1px solid transparent'
  },
  disabled: {
    backgroundColor: 'rgba(233,233,233,1)',
    color: 'rgba(158,168,180,1)',
    '&:hover': {
      backgroundColor: 'rgba(233,233,233,1) !important',
      color: 'rgba(158,168,180,1)',
    }
  },
  enabled: {
    backgroundColor: 'salmon',
    color: 'white !important',
    '&:hover': {
      backgroundColor: 'white !important',
      color: 'salmon !important',
      border: '1px solid salmon'
    }
  }
}));

export default function IntegrationCard(props) {
  const classes = useStyles();
  const { title, icon, disabled, onClick, buttonText } = props;
  const buttonStyle = (disabled === true) ? 'disabled' : 'enabled';
  return (
    <Card className={classes.root} variant="outlined">
      <CardMedia
        className={classes.media}
        image={props.icon}
      />    
      <CardContent>
        <h3 className={classes.header}> {title} </h3>
      </CardContent>
      <CardActions style={{justifyContent: 'center'}}>
      {
        (disabled === true) ? (
          <Button className={`${classes.button} ${classes[buttonStyle]}`} center disabled>{buttonText}</Button>
        ) : (
          <Button onClick={onClick} className={`${classes.button} ${classes[buttonStyle]}`} center>{buttonText}</Button>
        )
      }
      </CardActions>
    </Card>
  );
};

