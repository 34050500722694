import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation
} from "react-router-dom";
import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import MuiDrawer from '@material-ui/core/Drawer';
import UpcomingCalls from './UpcomingCalls';
import CompletedCalls from './CompletedCalls';
import MeetingPage from './MeetingPage';
import WorkspaceMembers from './WorkspaceMembers';
import IntegrationsPartners from './IntegrationsPartners';
import PublicReport from './PublicReport';
import Signup from './Signup';
import Invitation from './Invitation';
import LiveMeeting from './LiveMeeting';
import CreateWorkspace from './CreateWorkspace';
import SideNav from './SideNav';
import Toolbar from '@material-ui/core/Toolbar';
import TopNav from './TopNav';
import utils from './utils';
import Yobs from './../Services/Yobs';
import yobsLogo from './../Assets/logo.svg';
import CircularProgress from '@material-ui/core/CircularProgress';

import config from '../config';

const drawerWidth = 240;
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const useStyles = makeStyles((theme) => ({
  yobsLogo: {
    margin: '15px 0px 15px 0px'
  },
  loader: {
    position: 'relative',
    top: '100px'
  }
}));

function DashboardContent() {
  const classes = useStyles();
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const [workspaces, setWorkspaces] = useState([]);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(undefined);
  const [workspaceName, setWorkspaceName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [shouldCreateWorkspace, setShouldCreateWorkspace] = useState(false);

  useEffect( () => {
    fetchData();
  }, []);


  // The React Hook below refreshes the page if workspaceId changes
  const previousWorkspaceState = usePrevious({selectedWorkspaceId});
  useEffect(() => {
    if (selectedWorkspaceId && previousWorkspaceState.selectedWorkspaceId) {
      const previousWorkspaceId = previousWorkspaceState.selectedWorkspaceId;
      const newWorkspaceId = selectedWorkspaceId;
      if (window.location.href.includes(previousWorkspaceId)) {
        if (previousWorkspaceState.selectedWorkspaceId !== selectedWorkspaceId) {
          window.location.href = window.location.href.replace(previousWorkspaceId, newWorkspaceId);
        }
      }
    }
  }, [selectedWorkspaceId]);

  // Custom webhook to capture previous state values when using React.useEffect()
  // Specifically to capture previousWorkspaceId when selectedWorkspaceId value changes
  function usePrevious(value) {
    const ref = React.useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }


  const fetchData = async () => {
    if (location.pathname.startsWith("/report/") ||
        location.pathname.startsWith("/signup") || 
        location.pathname.startsWith("/invitation")) {
        setIsLoading(false);
        return;
    }
    setIsLoading(true);

    await getYobsAccountId();
    const teamsData = await Yobs.getTeams();
    if (teamsData.object.teams.length === 0) {
      setShouldCreateWorkspace(true);
      setIsLoading(false);
      return;
    }
    const teamIds = [];
    const teamsDataWithColors = teamsData.object.teams.map( (team) => {
      teamIds.push(team.teamId);
      return Object.assign(team, {color: utils.getDarkColor()});
    });
    setWorkspaces(teamsDataWithColors);

    const selectedWorkspaceId = (await Yobs.getSettings()).object.selectedTeamId;
    if (selectedWorkspaceId && teamIds.includes(selectedWorkspaceId)) {
      setSelectedWorkspaceId(selectedWorkspaceId);
    } else {
      setSelectedWorkspaceId(teamsDataWithColors[0].teamId);
      Yobs.updateSettings({selectedTeamId: teamsDataWithColors[0].teamId});
    }
    setIsLoading(false);
  };

  const getYobsAccountId = async () => {
    //
    // getUser to cache results, and check for auth
    //
    if (location.pathname.startsWith("/live-meeting")) {
      const meetingUuid = encodeURIComponent(encodeURIComponent(location.pathname.replace("/live-meeting/","")));
      const redirectionUrl = config.liveMeetingUrl.replace('meetingId', meetingUuid);
      return (await Yobs.getUser(redirectionUrl)).object.yobsAccountId;
    }
    return (await Yobs.getUser()).object.yobsAccountId;
  }

  if (isLoading) {
    return (
      <CircularProgress className={classes.loader}/>
    )
  }
  //
  // Noauth states
  // to-do: refactor, there should be a better way to handle noauth states
  //
  if (location.pathname.startsWith("/report/")) {
    return (
      <PublicReport />
    );
  }
  if (location.pathname.startsWith("/signup")) {
    return (
      <Signup />
    );
  }
  if (location.pathname.startsWith("/invitation")) {
    return (
      <Invitation />
    );
  }
  if (location.pathname.startsWith("/live-meeting/")) {
    return (
      <LiveMeeting workspaces={workspaces} />
    );
  }

  const onChangeSelectedWorkspace = async (ev) => {
    const newWorkspaceId = ev.target.value;
    await Yobs.updateSettings({selectedTeamId: newWorkspaceId});
    setSelectedWorkspaceId(newWorkspaceId);
  };

  const handleCreateWorkspace =  async () => {
    const createTeamResponse = await Yobs.createTeam(workspaceName);
    await Yobs.updateSettings({selectedTeamId: createTeamResponse.object.teamId});
    await fetchData();
    setShouldCreateWorkspace(false);
  }

  const handleWorkspaceNameChange = (ev) => {
    setWorkspaceName(ev.target.value);
  };

  if (shouldCreateWorkspace) {
    return (
      <CreateWorkspace 
        workspaceName={workspaceName} 
        createWorkspace={handleCreateWorkspace} 
        handleWorkspaceNameChange={handleWorkspaceNameChange}
      />
    )
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <TopNav toggleDrawer={() => setOpen(!open)} open={open}/>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <img src={yobsLogo} className={`width-50 ${classes.yobsLogo}`} alt="yobs-logo"/>
        </Toolbar>
        <Divider />
        <List>
          <SideNav workspaces={workspaces} selectedWorkspaceId={selectedWorkspaceId} onChangeWorkspace={onChangeSelectedWorkspace} drawerOpen={open}/>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} style={{position:'relative',bottom:'-3px'}}>
          <Switch>
            <Route path="/upcoming-calls">
              <UpcomingCalls />
            </Route>          
            <Route path="/completed-calls/:selectedWorkspaceId" component={CompletedCalls}>
            </Route>
            <Route path="/meeting/:meetingId" component={MeetingPage}>
            </Route>
            <Route path="/workspace-members/:selectedWorkspaceId" component={WorkspaceMembers}>
            </Route>
            <Route path="/integrations-partners/:selectedWorkspaceId" component={IntegrationsPartners}>
            </Route>
            <Route exact path="/" render={() => (
                <Redirect to="/upcoming-calls"/>
            )}/>
          </Switch>        
        </Container>
      </Box>
    </Box>
  );
}

export default function Dashboard() {
  return (
    <Router>
      <DashboardContent />
    </Router>
  );
}
