import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import Yobs from './../../Services/Yobs';
import MemberList from './MemberList';
import InviteModal from './InviteModal';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  loader: {
    position: 'relative !important',
    top: '100px !important'
  }
}));

function WorkspaceMembersContent(props) {
  const classes = useStyles();
  const { selectedWorkspaceId } = useParams();
  const [meetings, setMeetings] = useState(['loading']);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [invitationLink, setInvitationLink] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [teamOwner, setTeamOwner] = useState(undefined);
  const [userRole, setUserRole] = useState(undefined);

  const [emailChips, setEmailChips] = useState([]);

  useEffect( () => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const getUserResponse = await Yobs.getUser();
    const data = (await Yobs.getTeamMembers(selectedWorkspaceId)).object;
    const teamMemberAccountIds = data.teamMembers.map( (member) => member.memberClientDashboardAccountId);
    if (!teamMemberAccountIds.includes(getUserResponse.object.yobsAccountId)) {
      window.location.href = window.location.origin;
    }
    setTeamOwner(data.teamOwner.ownerClientDashboardAccountId);
    setTeamMembers(data.teamMembers);


    const userRole = data.teamMembers
    .filter( (member) => member.memberClientDashboardAccountId === getUserResponse.object.yobsAccountId)[0].memberRole;
    setUserRole(userRole);

    window.analytics.identify(getUserResponse.email, {
      name: getUserResponse.object.name,
      email: getUserResponse.object.email,
    });
    window.analytics.page();

    const inviteLink = await Yobs.getInvitationUrl(selectedWorkspaceId);
    setInvitationLink(inviteLink.object.teamSignupUrl);
    setIsLoading(false);
  };

  const changeAccess = async (id, newRole) => {
    await Yobs.updateUser();
  };
  const handleInviteEmailAddresses = async (emailAddresses) => {
    await Yobs.sendInvitationEmail(selectedWorkspaceId, emailAddresses);
    handleSnackbarOpen('Invitation email has been sent');
    fetchData();
  };
  const handleSnackbarClose = (event, reason) => {
    setSnackbarOpen(false);
  };
  const handleSnackbarOpen = (msg) => {
    setSnackbarOpen(msg);
  }
  const handleDeleteMember = async (teamId, accountId) => {
    await Yobs.deleteTeamMember(teamId, accountId);
    await fetchData();
  };
  const handleDeleteMemberByEmail = async (teamId, email) => {
    await Yobs.deleteTeamMemberByEmail(teamId, email);
    await fetchData();
  };
  const onUpdateAccess = async (teamId, accountId, accessLevel) => {
    if (["member","admin"].includes(accessLevel)) {
      await Yobs.updateTeamMember(teamId, accountId, {memberRole: accessLevel});
      await fetchData();
      handleSnackbarOpen('Member access level updated')
    }
  };

  if (isLoading) {
    return (
      <div>
        <CircularProgress className={classes.loader}/>
      </div>
    )
  }

  return (
    <div>
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
      message={snackbarOpen}
    >
      <MuiAlert elevation={6} variant="filled" severity={'success'}>
        {snackbarOpen}
      </MuiAlert>
    </Snackbar>
    <Grid container spacing={3} className="main-content main-content-container">
      <Grid item xs={6} md={6} lg={6}>
        <h1 className="text-navy"> Workspace Members </h1>
      </Grid>
      <Grid item xs={6} md={6} lg={6}>
        {
          (userRole === 'admin') && (
            <InviteModal
              invitationLink={invitationLink} 
              handleInviteEmailAddresses={handleInviteEmailAddresses} 
              setSnackbarOpen={() => handleSnackbarOpen('Link has been copied to clipboard.')}
            />
          ) 
        }
      </Grid>
      <Grid item xs={12} md={12} lg={12} className="main-content-container">
        <Paper
          className="background-initial"
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 240,
          }}
        >
          <MemberList 
            data={teamMembers} 
            handleDeleteMember={handleDeleteMember} 
            handleDeleteMemberByEmail={handleDeleteMemberByEmail}
            teamOwner={teamOwner}
            userRole={userRole}
            onUpdateAccess={onUpdateAccess}
          />
        </Paper>
      </Grid>
    </Grid>
    </div>
  );
}

export default function WorkspaceMembers() {
  return <WorkspaceMembersContent />;
}