import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MessageIcon from '@material-ui/icons/Message';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import CircularProgress from '@material-ui/core/CircularProgress';

import utils from './../utils';

const useStyles = makeStyles((theme) => ({
  tabSubContainer: {
    border: '1px solid rgba(200, 200, 200, 0.3)', 
  },
  commentsContainer: {
    backgroundColor: 'white',
  },
  commentsHeader: {
    fontSize: '1rem',
    margin: '22px 30px'
  },
  mediaContainerHr: {
    borderColor: 'rgba(200,200,200,0.1)',
    bottom: '1px',
    position: 'relative',
    margin: '0px'
  },
  commentsInnerContaner: {
    padding: '0px 10px 10px 10px',
    height: 'auto',
    maxHeight: '582px',
    overflow: 'scroll'
  },
  timestampComment: {
    color: 'rgba(74, 149, 219, 1)',
    textDecoration: 'underline',
    display: 'inline-block !important',
    cursor: 'pointer'
  },
}));

export default function MeetingInfoBar(props) {
  const classes = useStyles();

  if (props.comments === 'loading') {
    return (
      <div className={`${classes.tabSubContainer} ${classes.commentsContainer}`}>
         <h3 className={classes.commentsHeader}> All comments </h3>
         <hr className={classes.mediaContainerHr} style={{marginBottom: '20px'}}/>
         <div className={classes.commentsInnerContaner}>
          <CircularProgress />
         </div>
      </div>        
    )
  }
  const sortedComments = props.comments.sort( (a,b) => {
    return (new Date(b.createdAt)) - (new Date(a.createdAt))
  });
  const processedComments = sortedComments.map( (comment) => {
    return Object.assign(comment, {commentText: utils.parseCommentForMentions(comment.commentText, props.workspaceMembers)});
  });

  return (
    <div className={`${classes.tabSubContainer} ${classes.commentsContainer}`}>
       <h3 className={classes.commentsHeader}> All comments </h3>
       <hr className={classes.mediaContainerHr} style={{marginBottom: '20px'}}/>
       {
         props.comments.length === 0 && (
            <Grid item xs={12} md={12} fullWidth style={{margin: '40px', textAlign: 'center'}}>
              <div>
                <MessageIcon style={{fontSize: '50px'}}/>
                <h3> No comments yet </h3>
              </div>
            </Grid>
         )
       }
       <div className={classes.commentsInnerContaner}>
       {
         processedComments.map( (comment) => {
          return (
            <Grid item xs={12} md={12} fullWidth style={{marginBottom: '20px'}}>
              <div>
                <List dense={false}>
                  <ListItem >
                    <ListItemAvatar>
                      <Avatar src={comment.picture}>
                        {comment.commenterName.slice(0,1).toUpperCase()}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={<b>{comment.commenterName}</b>}
                    />
                    <ListItemSecondaryAction>
                      <b>{utils.timeDifference(Date.now(), new Date(comment.createdAt))}</b>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
                <List dense={false}>
                  <ListItem>
                    <ListItemText
                      primary={
                        <React.Fragment>
                        <span>
                          <div className={classes.timestampComment} onClick={() => props.skipTo(comment.timestampOfComment)}>
                            {utils.processTimestamp(comment.timestampOfComment)}
                          </div> - {comment.commentText}
                          </span>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
              </div>
            </Grid>
          )
         })
       }
       </div>
     </div>      
  )
};

