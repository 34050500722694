import * as React from 'react';
import {
  NavLink
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PieChartIcon from '@material-ui/icons/PieChart';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import GroupIcon from '@material-ui/icons/Group';
import IntegrationIcon from '@material-ui/icons/GroupWork';
import utils from './utils';

const useStyles = makeStyles((theme) => ({
  formControl: {
    backgroundColor: 'white'
  },
  square: {
    width: 25,
    height: 25,
    fontSize: '1rem',
    display: 'inline-grid',
    margin: '10px 10px 10px 10px',
  },
  menuItemHelper: {
    fontSize: '0.6rem',
    color: 'rgba(84, 84, 84)',
    position: 'relative',
    bottom: 20,
    left: 58
  },
  menuItem: {
    fontSize: '0.875rem !important'
  },
  menuItemText: {
    position: 'relative',
    bottom: 'px'
  }
}));

export default function SideNav(props)  {
  const classes = useStyles();

  return (
    <div>

      <FormControl className={classes.formControl} fullWidth>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={props.selectedWorkspaceId}
          onChange={props.onChangeWorkspace}
        >
          {
            props.workspaces.map( (team, index) => {
              return (
                <MenuItem value={team.teamId} className={classes.menuItem} key={index}>
                  <Avatar variant="square" className={classes.square} size="small" style={{backgroundColor: team.color}}>
                  {team.teamName.slice(0,1).toUpperCase()}
                  </Avatar>
                  <span className={classes.menuItemText}>{utils.capitalizeFirstLetter(team.teamName)}</span>
                </MenuItem>
              )
            })
          }
        </Select>
      </FormControl>
      {
        props.drawerOpen && (
          <div className={classes.menuItemHelper}>Workspace</div>
        )
      }

      <NavLink to="/upcoming-calls" activeClassName="text-salmon background-light-salmon" className="sideNavLink">
        <ListItem button className="text-inherit">
          <ListItemIcon className="text-inherit">
            <PieChartIcon className="text-inherit" style={{'position':'relative','left': '6px'}}/>
          </ListItemIcon>
          <ListItemText primary="Upcoming Calls" disableTypography={true} />
        </ListItem>
      </NavLink>

      <NavLink to={`/completed-calls/${props.selectedWorkspaceId}`} activeClassName="text-salmon background-light-salmon" className="sideNavLink">
        <ListItem button className="text-inherit">
          <ListItemIcon className="text-inherit">
            <VideoCallIcon className="text-inherit" style={{'position':'relative','left': '6px'}}/>
          </ListItemIcon>
          <ListItemText primary="Completed Calls" disableTypography={true} />
        </ListItem>
      </NavLink> 

      <NavLink to={`/workspace-members/${props.selectedWorkspaceId}`} activeClassName="text-salmon background-light-salmon" className="sideNavLink">
        <ListItem button className="text-inherit">
          <ListItemIcon className="text-inherit">
            <GroupIcon className="text-inherit" style={{'position':'relative','left': '6px'}}/>
          </ListItemIcon>
          <ListItemText primary="Workspace" disableTypography={true} />
        </ListItem>
      </NavLink> 
      
      <NavLink to={`/integrations-partners/${props.selectedWorkspaceId}`} activeClassName="text-salmon background-light-salmon" className="sideNavLink">
        <ListItem button className="text-inherit">
          <ListItemIcon className="text-inherit">
            <IntegrationIcon className="text-inherit" style={{'position':'relative','left': '6px'}}/>
          </ListItemIcon>
          <ListItemText primary="Integrations" disableTypography={true} />
        </ListItem>
      </NavLink> 
    </div>
  );
}

