import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Grid from '@material-ui/core/Grid';

import FolderIcon from '@material-ui/icons/Folder';
import yobsLogo from './../../Assets/logo.svg';
import greenhouseLogo from './../../Assets/greenhouse-logo.png';


const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: '2px solid rgba(216,216,216,0.2)',
    height: 175,
    width: '100%',
    position: 'relative',
    right: 25,
    padding: '0px 25px 0px 25px'
  },
  red: {
    backgroundColor: 'rgba(254,180,180,1)'
  },
  red_inner: {
    backgroundColor: 'rgba(252,15,29,1)'
  },  
  green: {
    backgroundColor: 'rgba(181,236,194,1)'
  },
  green_inner: {
    backgroundColor: 'rgba(29,192,55.1)'
  },
  callStatus: {
    width: '20px',
    height: '20px',
    borderRadius: '50px',
    margin: '0 auto',
    textAlign: 'center',
    display: 'inline',
    marginRight: 10,
  },
  callStatus_inner: {
    width: '11px',
    height: '11px',
    borderRadius: '50px',
    textAlign: 'center',
    marginRight: 10,
    position: 'relative',
    top: 4,
    left: 4
  },
  callStatusContainer: {
    backgroundColor: 'white',
    padding: 10,
    height: 23,
    borderRadius: 5,
    border: '2px solid rgba(216,216,216,0.2)',
    textAlign: 'center',
    display: 'inline-flex',
    position: 'relative',
    bottom: 4,
    float: 'right'
  },
  topHeaderContainer: {
    backgroundColor: 'rgba(216,216,216,0.2)',
    padding: 10,
    height: 100
  },
  bottomContainer: {
    padding: 20
  },
  yobsLogo: {
    paddingTop: 5
  },
  ghLogo: {
    width: 10,
    cursor: 'pointer'
  },
  userContainer: {
    position: 'relative',
    top: 39,
    width: 'calc(100% + 10px)',
    right: 5
  },
  callStatusText: {
    textAlign: 'center',
    margin: '0 auto',
    display: 'inline',
    position: 'relative',
    top: 3,
    width: 'max-content',
    marginRight: 5
  }
}));

export default function Header(props) {
  const classes = useStyles();

  const goToGreenhouse = () => {
    window.open(props.ghScoreCard, '_blank').focus();
  };

  const getCallStatusColor = (state) => {
    if (state === '2_confirm-greenhouse') {
      return 'none'
    }
    if (state === '3_wait-for-meeting-started') {
      return 'none'
    }
    if (state === '4_live-meeting-feedback-tab') {
      return 'green'
    }
    if (state === '5_completed-meeting') {
      return 'red'
    }
  };
  const getCallStatus = (state) => {
    if (state === '2_confirm-greenhouse') {
      return 'none'
    }
    if (state === '3_wait-for-meeting-started') {
      return 'none'
    }
    if (state === '4_live-meeting-feedback-tab') {
      return 'Live Call'
    }
    if (state === '5_completed-meeting') {
      return 'Call Completed'
    }
  };

  const callStatusColor = getCallStatusColor(props.state);
  const callStatus = getCallStatus(props.state);

  return (
    <div className={classes.root}>
      <Grid container spacing={10} className={classes.topHeaderContainer}>
        <Grid item xs={6} md={6} lg={6}>
          <img src={yobsLogo} className={`width-50 ${classes.yobsLogo}`} alt="yobs-logo"/>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          {
            (callStatus !== 'none') && (
              <div className={classes.callStatusContainer}>
                <div className={`${classes[callStatusColor]} ${classes.callStatus}`}>
                  <div className={`${classes[callStatusColor+"_inner"]} ${classes.callStatus_inner}`}>
                  </div>
                </div>
                <h5 className={classes.callStatusText}> {callStatus} </h5>
              </div>
            )
          }
        </Grid>
      </Grid>      
      <List dense={false} className={classes.userContainer}>
        <ListItem className={classes.bottomContainer}>
          <ListItemAvatar>
            <Avatar alt={props.user.name.slice(0,1).toUpperCase()} src={props.user.picture}>
              {props.user.name.slice(0,1).toUpperCase()}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<b>{props.user.name}</b>}
          />
          <ListItemIcon style={{display: 'block'}}>
            {
              (props.ghScoreCard) && (
                <div onClick={goToGreenhouse} style={{float: 'right'}}>
                  <img src={greenhouseLogo} alt="greenhouse-logo" className={`width-50 ${classes.ghLogo}`}/>
                </div>
              )
            }
          </ListItemIcon>
        </ListItem>
      </List>    
    </div>
  );
};

