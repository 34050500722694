import fetch from 'node-fetch';
import config from '../config';

const getMeetingComments = async (meetingUuid) => {
  let url = `${config.meetingCommentsUrl.replace('meetingUuid', meetingUuid)}`;
  const invitation = await fetch(url, {
    credentials: 'include'
  });
  validateResponseStatus(invitation.status);
  const response = await invitation.json();
  return response;
}

const createMeetingComment = async (meetingUuid, comment, timestamp) => {
  let url = `${config.meetingCommentsUrl.replace('meetingUuid', meetingUuid)}`;

  const meetingComment = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({
      timestamp, comment
    })
  });
  validateResponseStatus(meetingComment.status);
  const response = await meetingComment.text();
  return response;
}

const updateMeeting = async (meetingUuid, updateParams) => {
  let url = `${config.getMeetingUrl.replace('meetingId',meetingUuid)}`;
  const meeting = await fetch(url, {
    method: 'PATCH',
    body: JSON.stringify(updateParams),
    credentials: 'include'
  });
  validateResponseStatus(meeting.status);
  const response = await meeting.text();
  return response;
};

const getInvitationUrl = async (teamId) => {
  let url = `${config.getInvitationUrl.replace('teamId', teamId)}`;
  const invitation = await fetch(url, {
    credentials: 'include'
  });
  validateResponseStatus(invitation.status);
  const response = await invitation.json();
  return response;
};

const sendInvitationEmail = async (teamId, emailAddresses) => {
  let url = `${config.sendInvitationEmail.replace('teamId', teamId)}`;
  const sendEmail = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({emailAddresses})
  });
  validateResponseStatus(sendEmail.status);
  const response = await sendEmail.json();
  return response;
};

const getPublicTeam = async (teamId) => {
  let url = `${config.publicTeamsUrl.replace('teamId',teamId)}`;
  const team = await fetch(url, {
    credentials: 'include'
  });
  validateResponseStatus(team.status);
  const response = await team.json();
  return response;
};

const deleteTeamMember = async (teamId, accountId) => {
  let url = `${config.specificTeamMemberUrl.replace('teamId',teamId).replace('yobsAccountId', accountId)}`;
  const deleteCall = await fetch(url, {
    method: 'DELETE',
    credentials: 'include'
  });
  validateResponseStatus(deleteCall.status);
  const response = await deleteCall.text();
  return response;
};

const deleteTeamMemberByEmail = async (teamId, email) => {
  let url = `${config.deleteTeamMemberByEmailUrl.replace('teamId',teamId).replace('emailToDelete', email)}`;
  const team = await fetch(url, {
    method: 'DELETE',
    credentials: 'include'
  });
  validateResponseStatus(team.status);
  const response = await team.text();
  return response;
};


const getTeams = async () => {
  let url = `${config.teamsUrl}`;
  const teams = await fetch(url, {
    credentials: 'include'
  });
  validateResponseStatus(teams.status);
  const response = await teams.json();
  return response;
};

const createTeam = async (teamName) => {
  let url = `${config.teamsUrl}`;
  const params = {teamName}
  const team = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(params),
    credentials: 'include'
  });
  validateResponseStatus(team.status);
  const response = await team.json();
  return response;
};

const getReport = async (meetingId) => {
  let url = `${config.getReportUrl.replace('meetingId', meetingId)}`;
  const meeting = await fetch(url, {
    credentials: 'include'
  });
  validateResponseStatus(meeting.status);
  const response = await meeting.text();
  return response;
};

const getMeeting = async (meetingId) => {
  let url = `${config.getMeetingUrl.replace('meetingId', meetingId)}`;
  const meeting = await fetch(url, {
    credentials: 'include'
  });
  validateResponseStatus(meeting.status);
  const response = await meeting.json();
  return response;
};

const getTeamMembers = async (teamId) => {
  let url = `${config.getTeamMembersUrl.replace('teamId', teamId)}`;
  const meeting = await fetch(url, {
    credentials: 'include'
  });
  validateResponseStatus(meeting.status);
  const response = await meeting.json();
  return response;
};

const updateTeamMember = async (teamId, accountId, updateParams) => {
  let url = `${config.specificTeamMemberUrl.replace('teamId',teamId).replace('yobsAccountId', accountId)}`;
  const meeting = await fetch(url, {
    method: 'PATCH',
    body: JSON.stringify(updateParams),
    credentials: 'include'
  });
  validateResponseStatus(meeting.status);
  const response = await meeting.text();
  return response;
};

const getPublicMeeting = async (publicKey) => {
  let url = `${config.getPublicMeetingUrl.replace('publicKey', publicKey)}`;
  const meeting = await fetch(url);
  validateResponseStatus(meeting.status);
  const response = await meeting.json();
  return response;
};

const getPublicMeetingComments = async (publicKey) => {
  let url = `${config.getPublicMeetingCommentsUrl.replace('publicKey', publicKey)}`;
  const meeting = await fetch(url);
  validateResponseStatus(meeting.status);
  const response = await meeting.json();
  return response;
};

const getMeetings = async (nextPageToken, meetingType = 'upcoming') => {
  let url = `${config.getMeetingsUrl}?meetingType=${meetingType}`;
  if (nextPageToken) {
    url += `&nextPageToken=${nextPageToken}`
  }
  const meetings = await fetch(url, {
    credentials: 'include'
  });
  validateResponseStatus(meetings.status);
  const response = await meetings.json();
  return response;
};

const getMeetingStatus = async (meetingId) => {
  let url = `${config.getMeetingStatusUrl.replace('meetingId', meetingId)}`;
  const meeting = await fetch(url, {
    credentials: 'include'
  });
  validateResponseStatus(meeting.status);
  const response = await meeting.json();
  return response;
};

const getMeetingsByZoomUserId = async (nextPageToken, zoomUserId) => {
  let url = `${config.getMeetingsUrl}?zoomUserId=${zoomUserId}`;
  if (nextPageToken) {
    url += `&nextPageToken=${nextPageToken}`
  }
  const meetings = await fetch(url, {
    credentials: 'include'
  });
  validateResponseStatus(meetings.status);
  const response = await meetings.json();
  return response;
};

const getSettings = async () => {
  const settings = await fetch(config.settingsUrl, {
    credentials: 'include'
  });
  validateResponseStatus(settings.status);
  return await settings.json();
};

const getUser = async (redirectionUrl) => {
  const settings = await fetch(config.getUserUrl, {
    credentials: 'include'
  });
  validateResponseStatus(settings.status, redirectionUrl);
  return await settings.json();
};

const updateSettings = async (settingsObj) => {
  const update = await fetch(config.settingsUrl, {
    method: 'PATCH',
    body: JSON.stringify(settingsObj),
    credentials: 'include'
  });
  validateResponseStatus(update.status);
  return await update.text();
};

const disableProcessing = async (meetingId) => {
  const url = config.disableProcessingUrl.replace('meetingId', encodeURIComponent(meetingId));
  const update = await fetch(url, {
    method: 'PATCH',
    credentials: 'include'
  });
  validateResponseStatus(update.status);
  return await update.text();
};

const enableProcessing = async (meetingId, params) => {
  const url = config.enableProcessingUrl.replace('meetingId', encodeURIComponent(meetingId));
  const update = await fetch(url, {
    method: 'PATCH',
    credentials: 'include',
    body: JSON.stringify({
      meetingType: params.meetingType, 
      jobFunction: params.jobFunction, 
      jobRole: params.jobRole
    })
  });
  validateResponseStatus(update.status);
  return await update.text();
};

const validateResponseStatus = (statusCode, redirectionUrl) => {
  if (statusCode === 403 && process.env.NODE_ENV !== 'development') {
    if (redirectionUrl) {
      window.location.href = config.loginUrl.concat(`?redirectionUrl=${redirectionUrl}`);
      return;
    }
    window.location.href = config.loginUrl;
  }
};

const getTranscriptString = async (downloadUrl) => {
  const response = await fetch(downloadUrl, {
    method: 'GET'
  });
  validateResponseStatus(response.status);
  return await response.text();
};

const logOut = async () => {
  const url = config.logOutUrl;
  const update = await fetch(url, {
    method: 'GET',
    credentials: 'include'
  });
  validateResponseStatus(update.status);
  return await update.text();
};

const _exports = {
  getMeeting, getMeetings, getTeamMembers, getReport, getSettings, getUser, getTranscriptString, getMeetingStatus,
  getPublicMeeting, getPublicMeetingComments, disableProcessing, enableProcessing, updateSettings, logOut, getTeams, getPublicTeam, 
  updateMeeting, getTeamMembers, createTeam, getInvitationUrl, sendInvitationEmail, deleteTeamMember, deleteTeamMemberByEmail, 
  getMeetingsByZoomUserId, getMeetingComments, createMeetingComment, updateTeamMember
};
export default _exports;
