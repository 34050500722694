import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TabPanel from './../../Components/TabPanel';


function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 550,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(0,0,0,0.1)',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '6px'
  },
  settingsContainer: {
    padding: '1px 15px 15px 15px',
    backgroundColor: 'white',
    border: '2px solid rgb(20 20 20 / 3%) !important',
    marginTop: '80px'
  },
  divider: {
    width: 'calc(100% + 30px)',
    position: 'relative !important',
    right: '15px',
    border: '1px inset rgba(0, 0, 0, 0.02) !important'
  },
  inModalDivider: {
    width: 'calc(100% + 70px)',
    position: 'relative !important',
    right: '35px',
    border: '1px inset rgba(150, 150, 150, 0.1) !important'
  },
  tabDivider: {
    width: '100%',
    position: 'relative !important',
    bottom: '7px',
    border: '1px inset rgba(150, 150, 150, 0.1) !important',
    marginBottom: '0px'
  },
  tabDivider2: {
    width: '100%',
    border: '1px inset rgba(150, 150, 150, 0.1) !important',
    margin: '20px 0px 20px 0px !important'
  },
  margin20: {
    margin: '0px 0px 20px 0px !important'
  },
  toggleOffset: {
    position: 'relative',
    top: '7px'
  },
  formControl: {
    margin: '0px 0px 0px 20px',
    minWidth: 120,
    position: 'relative',
    bottom: '3px',
  }  
}));

export default function Settings(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [modalTab, setModalTab] =React.useState(1);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSettingsChange = (settingName, newValue) => {
    switch(settingName) {
      case 'record_all':
        props.onChange(newValue, null, null, null);
        break;
      case 'share_all':
        props.onChange(null, newValue, null, null);
        break;
      case 'email_notification':
        props.onChange(null, null, newValue, null);
        break;
      case 'video_language':
        props.onChange(null, null, null, newValue);
        break;
      default:
        break;
    }
  }
  const handleTabChange = (event, newValue) => {
    setModalTab(newValue);
  };

  const RecordingTab = () => {
    if (props.isProcessing) {
      return (
        <TabPanel value={modalTab} index={1}>
          <CircularProgress />
        </TabPanel>
      );
    }

    return (
      <TabPanel value={modalTab} index={1}>
        <h4 style={{marginTop: '0px'}}> Auto-record calendar events </h4>
        <Grid container spacing={24}  justify="space-between">
          <Grid item> Calendar connected
          </Grid>
          <Grid item> {props.user.email}
          </Grid>
        </Grid>
        <hr className={classes.tabDivider2}/>
        <Grid container spacing={24} m={15} className={classes.margin20}>
          <Grid item>
            <Switch
              checked={props.data.RECORD_ALL}
              onChange={(ev) => handleSettingsChange('record_all', ev.target.checked)}
              name="record_all"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>
          <Grid item className={classes.toggleOffset}>
            Record all calls
          </Grid>
        </Grid>
        <Grid container spacing={24}>
          <Grid item>
            <Switch
              checked={props.data.SHARE_ALL}
              onChange={(ev) => handleSettingsChange('share_all', ev.target.checked)}
              name="record_all"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Grid>
          <Grid item className={classes.toggleOffset}>
            Share report with all participants
          </Grid>
        </Grid>
        <hr className={classes.tabDivider2}/>
        <h4> Transcription </h4>
        <Grid container spacing={24}>
          <Grid item>
            Language
          </Grid>
          <Grid item>
            <FormControl className={classes.formControl}>
              <Select
                value={props.data.VIDEO_LANGUAGE}
                onChange={(ev) => handleSettingsChange('video_language', ev.target.value)}
                displayEmpty
              >
                <MenuItem value={'english'}>English</MenuItem>
                <MenuItem value={'french'}>French</MenuItem>
                <MenuItem value={'italian'}>Italian</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>        
      </TabPanel>    
    )
  };

  const AccountsTab = (
    <TabPanel value={modalTab} index={0}>
      <h4> Account Info </h4>
      <Grid container spacing={24}  justify="space-between" className={classes.margin20}>
        <Grid item> 
          Gmail connected
        </Grid>
        <Grid item>
          {props.user.email}
        </Grid>
      </Grid>
      <Grid container spacing={24}  justify="space-between">
        <Grid item> 
          Name
        </Grid>
        <Grid item> 
          {props.user.name}
        </Grid>
      </Grid>
      <br />
    </TabPanel>
  );

  const ModalBody = (
    <div style={modalStyle} className={classes.paper}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <h4 className="text-center">Account Settings</h4>
          <hr className={classes.inModalDivider} />
        </Grid>
      </Grid>
      <AppBar position="static" className="background-white text-black box-shadow-0">
        <Tabs value={modalTab} onChange={handleTabChange} aria-label="simple tabs example">
          <Tab label="Account" />
          <Tab label="Recording"/>
        </Tabs>
      </AppBar>
      <hr className={classes.tabDivider}/>
      { AccountsTab }
      { RecordingTab() }
    </div>
  );

  return (
    <React.Fragment>
      <div className={classes.settingsContainer}>
        <h4> Settings </h4>
        <hr className={classes.divider}/>
        <p>
          Here you can edit account information and recording settings. (e.g. Share report settings and more)
        </p>
        <Button onClick={handleOpen} className={`salmon-button width-100`}>
          Edit Settings
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {ModalBody}
        </Modal>
      </div>   
    </React.Fragment>
  );
}
