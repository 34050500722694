const parseIsoDateTime = (isoDateTime, duration) => {
  if (!isoDateTime) {
    return "Recurring";
  }

  // Example input format: 2021-09-02T01:00:00Z
  const dateToday = new Date();
  const dd = String(dateToday.getDate()).padStart(2, '0');
  const mm = String(dateToday.getMonth() + 1).padStart(2, '0');
  const yyyy = dateToday.getFullYear();
  const today = `${yyyy}-${mm}-${dd}`;

  const startTime = new Date(isoDateTime).toLocaleString()
  // Example startTime: '9/1/2021, 6:00:00 PM'
  const endTime = new Date(new Date(isoDateTime).getTime() + duration*60000).toLocaleString().split(',')[1]
  // Example endTime: 6:00:00 PM'

  if (isoDateTime.split(',')[0] === today) {
    return `Today | ${isoDateTime.split(',')[1]} - ${endTime}`;
  }
  return `${startTime.replace(',',' |')} - ${endTime}`;
};

const isoDateToHumanReadable = (isoDateTime) => {
  return new Date(isoDateTime).toString().split(' ').slice(0,4).join(' ');
}

const isoDateToLocalTime = (isoDateTime) => {
  return new Date(isoDateTime).toString().split(' ').slice(4,6).join(' ');
}

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  
  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!');
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
  });
}

function meetingFunctionMap(string) {
  const map = {
    hiring: "Interview",
    talentDevelopment: "1:1",
    "Sales": "Sales",
    "Engineering": "Engineering",
    "Design": "Design",
    "Product": "Product",
    "Operations": "Operations",
    "Marketing": "Marketing",
    "Management": "Management",
    "Other": "Other"
  }
  return map[string];
}

function meetingRoleMap(string) {
  const map = {
    "Business Analyst": "Business Analyst",
    "Data Analyst": "Data Analyst",
    "Project Manager": "Project Manager",
    "Attorney": "Attorney",
    "CEO": "CEO",
    "CFO": "CFO",
    "CMO": "CMO",
    "COO": "COO",
    "CTO": "CTO",
    "Growth Hacker": "Growth Hacker",
    "Marketing Manager": "Marketing Manager",
    "Content Creator": "Content Creator",
    "Social Media Manager": "Social Media Manager",
    "Copywriter": "Copywriter",
    "Digital Marketing Manager": "Digital Marketing Manager",
    "Product Marketing Manager": "Product Marketing Manager",
    "Finance/Accounting": "Finance/Accounting",
    "H.R.": "H.R.",
    "Office Manager": "Office Manager",
    "Recruiter": "Recruiter",
    "Customer Service": "Customer Service",
    "Operations Manager": "Operations Manager",
    "Chief of Staff": "Chief of Staff",
    "Product Manager": "Product Manager",
    "User Researcher": "User Researcher",
    "Visual Designer": "Visual Designer",
    "Creative Director": "Creative Director",
    "Product Designer": "Product Designer",
    "Graphic Designer": "Graphic Designer",
    "Design Manager": "Design Manager",
    "Software Engineer": "Software Engineer",
    "DevOps": "DevOps",
    "Hardware Engineer": "Hardware Engineer",
    "Mobile Engineer": "Mobile Engineer",
    "Frontend Engineer": "Frontend Engineer",
    "Machine Learning Engineer": "Machine Learning Engineer",
    "Android Developer": "Android Developer",
    "Engineering Manager": "Engineering Manager",
    "QA Engineer": "QA Engineer",
    "Mechanical Engineer": "Mechanical Engineer",
    "Full-Stack Engineer": "Full-Stack Engineer",
    "Data Engineer": "Data Engineer",
    "Security Engineer": "Security Engineer",
    "Data Scientist": "Data Scientist",
    "Software Architect": "Software Architect",
    "Systems Engineer": "Systems Engineer",
    "Backend Engineer": "Backend Engineer",
    "Embedded Engineer": "Embedded Engineer",
    "iOS Developer": "iOS Developer",
    "Sales Development Rep": "Sales Development Rep",
    "Sales Manager": "Sales Manager",
    "Business Development Manager": "Business Development Manager",
    "Account Executive": "Account Executive",
    "Customer Success Manager": "Customer Success Manager"
  }
  return map[string];
}

function getDarkColor() {
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += Math.floor(Math.random() * 10);
    }
    return color;
}

function timeDifference(current, previous) {

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerMinute) {
         // return Math.round(elapsed/1000) + ' seconds ago';   
         return 'A few seconds ago';
    }

    else if (elapsed < msPerHour) {
         return Math.round(elapsed/msPerMinute) + ' minutes ago';   
    }

    else if (elapsed < msPerDay ) {
         return Math.round(elapsed/msPerHour ) + ' hours ago';   
    }

    else if (elapsed < msPerMonth) {
        return 'approximately ' + Math.round(elapsed/msPerDay) + ' days ago';   
    }

    else if (elapsed < msPerYear) {
        return 'approximately ' + Math.round(elapsed/msPerMonth) + ' months ago';   
    }

    else {
        return 'approximately ' + Math.round(elapsed/msPerYear ) + ' years ago';   
    }
}

const processTimestamp = (timestamp) => {
  let minutes = String(Math.floor(timestamp / 60));
  if (minutes.length === 1) {
    minutes = '0' + minutes;
  }
  let seconds = String(Math.floor(timestamp - (minutes * 60)));
  if (seconds.length === 1) {
    seconds = '0' + seconds;
  }
  return `${minutes}:${seconds}`;
}

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function validateName(name) {
  const re = /^(([A-Za-z]+[\-\']?)*([A-Za-z]+)?\s)+([A-Za-z]+[\-\']?)*([A-Za-z]+)?$/;
  return re.test(String(name));
}

const removeDuplicates = (array) => {
  return [...new Set(array)];
};

const parseCommentForMentions = (string, workspaceMemberMap) => {
  if (!workspaceMemberMap) {
    return string;
  }

  let responseString = string.slice();
  workspaceMemberMap.forEach( (member) => {
    if (responseString.includes(member.memberClientDashboardAccountId)) {
      responseString = responseString.replace(member.memberClientDashboardAccountId, member.memberName);
    }
  });
  return responseString;
};

const _exports = {parseCommentForMentions, removeDuplicates, capitalizeFirstLetter, copyTextToClipboard, validateName, isoDateToHumanReadable, isoDateToLocalTime, parseIsoDateTime, meetingFunctionMap, meetingRoleMap, getDarkColor, timeDifference, processTimestamp, validateEmail};
export default _exports;
