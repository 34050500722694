import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ChangeAccessModal from './ChangeAccessModal';
import utils from './../utils';

const useStyles = makeStyles((theme) => ({
  zoomIcon: {
    fontSize: '40px',
    margin: '20px 0px 20px 10px'
  },
  tableRow: {
    border: '2px solid rgb(20 20 20 / 3%) !important',
    borderRadius: '5px',
    height: '100px',
    outline: '20px solid rgba(250,250,250,1) !important',
    margin: '10px',
    display: 'inline-block',
    backgroundColor: 'white',
    width: '100%'
  },
  zoomIconRow: {
    paddingRight: '0px !important'
  },
  tableContainer: {
    minWidth: '748px',
    backgroundColor: '#fafafa',
    position: 'relative',
    right: '10px',
    bottom: '10px'
  },
  floatRightCell: {
    float: 'right',
    position: 'relative',
    bottom: '70px'
  },
  roleCell: {
    padding: '0px 10px 0px 0px'
  },
  statusButton: {
    borderRadius: '25px',
    width: '100px',
    textAlign: 'center',
    padding: '8px'
  },
  meetingTitle: {
    fontFamily: 'PlusJakartaSans-Bold',
    marginBottom: '5px',
    display: 'block'
  },
  teamRow: {
  },
  roleButton: {
    width: '80px',
    borderRadius: '20px',
    textAlign: 'center',
    padding: '2px',
    fontSize: '0.75rem',
    cursor: 'initial'
  },
  admin: {
    backgroundColor: 'rgba(253, 217, 214, 1) !important',
    color: 'rgba(184, 85, 85, 1) !important',
  },
  member: {
    backgroundColor: 'rgba(244, 216, 185, 1) !important',
    color: 'rgba(226, 158, 73, 1) !important',
  }
}));

export default function MeetingList(props) {
  const classes = useStyles();
  const history = useHistory();

  if (props.data.length === 0) {
    return (
      <React.Fragment>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>
                <h3 className="text-center"> This workspace doesn't have any members yet. </h3>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }

  if (props.data[0] === 'loading') {
    return (
      <React.Fragment>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>          
                <CircularProgress />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }
  const PendingMemberRow = (member, index) => {
    return (
      <TableRow key={index} className={classes.tableRow}>
        <TableCell key={index} className={classes.zoomIconRow}>
          <AccountCircleIcon className={`text-salmon ${classes.zoomIcon}`} />
        </TableCell>
        <TableCell className={`${classes.teamRow}`}>
          <span className={classes.meetingTitle}>
            {member.email} (Waiting on response)
          </span>
          <span className='text-gray'>   
            {
              (member.memberRole === 'admin') && (
                <Button className={`${classes.roleButton} ${classes.admin}`}> Admin </Button>
              )  
            }
            {
              (member.memberRole === 'member') && (
                <Button className={`${classes.roleButton} ${classes.member}`}> Member </Button>
              )  
            }
          </span>
        </TableCell>
        <TableCell className={`${classes.teamRow} text-navy ${classes.roleCell} ${classes.floatRightCell}`}>
        {
          (props.userRole === 'admin' && member.memberClientDashboardAccountId !== props.teamOwner) && (
            <Button className={`${classes.statusButton}`} onClick={() => props.handleDeleteMemberByEmail(member.teamId, member.email)}>
              <DeleteOutlineIcon />
              Remove
            </Button>
          )
        }
        </TableCell>
        <TableCell className={`${classes.teamRow} text-navy ${classes.roleCell} ${classes.floatRightCell}`}>
        </TableCell>
      </TableRow>
    )
  };  
  const MemberRow = (member, index) => {
    return (
      <TableRow key={index} className={classes.tableRow}>
        <TableCell key={index} className={classes.zoomIconRow}>
          <AccountCircleIcon className={`text-salmon ${classes.zoomIcon}`} />
        </TableCell>
        <TableCell className={`${classes.teamRow}`}>
          <span className={classes.meetingTitle}>
            {utils.capitalizeFirstLetter(member.memberName)} ({member.email})
          </span>
          <span className='text-gray'>
            {
              (member.memberClientDashboardAccountId === props.teamOwner) && (
                <Button className={`${classes.roleButton} ${classes.admin}`}> Owner </Button>
              )  
            }          
            {
              (member.memberRole === 'admin' && member.memberClientDashboardAccountId !== props.teamOwner) && (
                <Button className={`${classes.roleButton} ${classes.admin}`}> Admin </Button>
              )  
            }
            {
              (member.memberRole === 'member') && (
                <Button className={`${classes.roleButton} ${classes.member}`}> Member </Button>
              )  
            }
          </span>
        </TableCell>
        <TableCell className={`${classes.teamRow} text-navy ${classes.roleCell} ${classes.floatRightCell}`}>
        </TableCell>
        <TableCell className={`${classes.teamRow} text-navy ${classes.roleCell} ${classes.floatRightCell}`}>
            {
              (props.userRole === 'admin' && member.memberClientDashboardAccountId !== props.teamOwner) && (
                <Button 
                  disabled={member.memberClientDashboardAccountId === props.teamOwner}
                  className={`${classes.statusButton}`} 
                  onClick={() => props.handleDeleteMember(member.teamId, member.memberClientDashboardAccountId)}
                  >
                  <DeleteOutlineIcon />
                  Remove
                </Button>
              )  
            }
        </TableCell>
        <TableCell className={`${classes.teamRow} text-navy ${classes.roleCell} ${classes.floatRightCell}`}>
          {
            (props.userRole === 'admin' && member.memberClientDashboardAccountId !== props.teamOwner) && (
              <ChangeAccessModal 
                disabled={member.memberClientDashboardAccountId === props.teamOwner}
                member={member} 
                onUpdateAccess={props.onUpdateAccess}
              />
            )  
          }        
        </TableCell>
        <TableCell className={`${classes.teamRow} text-navy ${classes.roleCell} ${classes.floatRightCell}`}>
        </TableCell>
      </TableRow>
    )
  };
  
  return (
    <React.Fragment>
      <Table size="small" className={classes.tableContainer}>
        <TableBody>
          {  props.data.map((meeting, index) => {
            if (meeting.memberStatus === 'invited') {
              return PendingMemberRow(meeting, index)
            } else {
              return MemberRow(meeting, index)
            }
          })}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
