import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import greenhouseLogo from './../../Assets/greenhouseicon.png';

import utils from './../utils';
import config from './../../config';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  divider: {
    width: 'calc(100% + 50px)',
    position: 'relative',
    right: 25,
    borderColor: 'rgba(0,0,0,0.1)',
    backgroundColor: 'rgba(0,0,0,0.1)',
    height: '2px',
    border: 'none',
    marginBottom: 20    
  },
  orDivider: {
    width: '100%',
    height: '20px', 
    borderBottom: '1px solid rgba(0,0,0,0.5)', 
    textAlign: 'center',    
  },
  orDividerInner: {
    fontSize: '14px',
    backgroundColor: '#fafafa',
    position: 'relative',
    top: '8px',
    padding: '10px',
    color: 'rgba(0,0,0,0.7)',
  },
  gh: {
    height: 20
  }
}));

export default function MeetingEnded(props) {
  const classes = useStyles();

  const ghButton = (
    <React.Fragment>
      <h5 style={{margin: '15px 0px 0px 0px'}}> Rate candidate in Greenhouse </h5>
      <a href={props.ghScoreCard} target="_blank">
        <Button className={`greenhouse-button width-100`}
          style={{'marginTop': 15}}
          startIcon={
            <img src={greenhouseLogo} alt="greenhouse-logo" className={`${classes.gh}`}/>
          }
        >
          Scorecard 
        </Button>
      </a>
      <div className={classes.orDivider}>
        <span className={classes.orDividerInner}>
          OR
        </span>
      </div>   
    </React.Fragment>
  )
  const meetingPageUrl = config.meetingPageUrl
    .replace('meetingId', encodeURIComponent(props.meetingUuid))
    .replace('workspaceId', props.workspaceId);

  return (
    <div className={classes.root}>
      {
        (props.ghScoreCard) && (ghButton)
      }
      <a href={meetingPageUrl} target="_blank">
        <Button className={`salmon-button width-100`} 
          style={{margin: '15px 0px 10px 0px'}}> 
          Open Yobs Meeting Page
        </Button>
      </a>
      <hr className={classes.divider} />
    </div>
  );
};

