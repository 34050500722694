import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import MuiDrawer from '@material-ui/core/Drawer';
import DisabledSideNav from './../../Components/DisabledSideNav';
import DisabledTopNav from './../../Components/DisabledTopNav';
import Toolbar from '@material-ui/core/Toolbar';
import Yobs from './../../Services/Yobs';
import MeetingPage from './../MeetingPage';
import yobsLogo from './../../Assets/logo.svg';

const drawerWidth = 240;
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const useStyles = makeStyles((theme) => ({
  yobsLogo: {
    margin: '30px 0px 30px 0px'
  }
}));

function PublicReportContent() {
  const classes = useStyles();
  const location = useLocation();
  const publicKey = location.pathname.split('/')[2];

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <DisabledTopNav open={true}/>
      <Drawer variant="permanent" open={true}>
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <img src={yobsLogo} className={`width-50 ${classes.yobsLogo}`} alt="yobs-logo"/>
        </Toolbar>
        <Divider />
        <List>
          <DisabledSideNav />
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <MeetingPage publicKey={publicKey}/>
        </Container>
      </Box>
    </Box>
  );
}

export default function PublicReport() {
  return (
    <PublicReportContent />
  );
}
