import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import Yobs from './../../../Services/Yobs';
import CircularProgress from '@material-ui/core/CircularProgress';
import config from '../../../config';
import utils from './../../utils';

import TextField from '@material-ui/core/TextField';
import greenhouseIcon from '../../../Assets/greenhouseicon.png';
import CandidateModalContent from "./CandidateModalContent";

function greenhouseDefaultLink() {
  const greenhouseDefaultLink = config.greenhouseDefaultLink;
  window.open(greenhouseDefaultLink, '_blank').focus();
}


function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 550,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(0,0,0,0.1)',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '6px'
  },
  candidateFullNameInput: {
    width: '100%',
    marginBottom: '20px'
  },

  candidateFullNameInputHeader: {
    marginBottom: '5px'
  },
  
  jobRoleInput: {
    width: '100%',
    marginBottom: '20px'
  },

  jobRoleInputHeader: {
    marginBottom: '5px'
  },
  
  header: {
    marginTop: '0px'
  },
  divider: {
    width: '600px !important',
    position: 'relative',
    right: '50px'
  },
  card: {
    overflow: 'initial'
  },
  greenhouseLink: {
    float: 'right',
    margin: '15px 7px 0px 0px',
    padding: '5px 10px 5px 10px !important'
  },
  greenhouseIcon: {
    width: '7px',
    margin: '5px'
  },
  autocomplete: {
    "&$hover:hover": {
      borderColor: "salmon !important"
    }
  }
}));

export default function GreenhouseCandidateModal(props) {
  const classes = useStyles();
  
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [fullnameInput, setFullnameInput] = React.useState('');
  const [rolenameInput, setRolenameInput] = React.useState('');
  const nameOptions = utils.removeDuplicates(props.candidatesDetails.map( (candidate) => candidate.fullname ));
  const [selectedName, setSelectedName] = React.useState('');

  const [roleOptions, setRoleOptions] = React.useState([]);
  const [selectedRole, setSelectedRole] = React.useState(-1);
  const [isLoading, setIsLoading] = React.useState(false);
  
  const handleOpen = () => {
    if (props.ghScoreCardUrl) {
      window.open(props.ghScoreCardUrl, '_blank').focus();
    } else {
      const availableRoles = utils.removeDuplicates(props.candidatesDetails
        .filter((candidate) => candidate.fullname === nameOptions[0])
        .map((candidate) => candidate.rolename))
      setRoleOptions(availableRoles);
      setSelectedRole(0);
      setSelectedName(nameOptions[0]); 
      setOpen(true);
    }

  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleNameChange = (event) => {
    const name = event.target.textContent;
    setSelectedName(name);
    const availableRoles = utils.removeDuplicates(props.candidatesDetails
      .filter((candidate) => candidate.fullname === name)
      .map((candidate) => candidate.rolename))
    setRoleOptions(availableRoles);
    setSelectedRole(0);
  };
  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };
  const onSubmitConfirm = async () => {
    const candidates = props.candidatesDetails
      .filter( (candidate) => candidate.fullname === selectedName)
      .filter( (candidate) => candidate.rolename === roleOptions[selectedRole])

    if (candidates.length) {
      setIsLoading(true);
      const candidate = candidates[0];
      await Yobs.updateMeeting(props.meetingUuid, {
        greenhouseCandidate: {
          applicationId: candidate.applicationId,
          candidateId: candidate.candidateId
        }
      });
      props.onSuccess();
      handleClose();
      setIsLoading(false);
    }
  };

  const ModalBody = (
    <div style={modalStyle} className={classes.paper}>
      <Card className={classes.card}>
        <CardContent>
            <h3 className={`text-navy ${classes.header}`}> Confirm Greenhouse candidate details
            </h3>
          <Divider className={classes.divider}/>
          <CandidateModalContent 
            nameOptions={nameOptions}
            handleNameChange={handleNameChange}
            selectedRole={selectedRole}
            handleRoleChange={handleRoleChange}
            roleOptions={roleOptions}
          />
        </CardContent>
        <CardActions>
          <Typography style={{ flex: 1, padding: '0px 7px' }}>
            <Button size="large" style={{float:'right'}} 
              className={`salmon-button`}
              onClick={onSubmitConfirm}>
                Confirm
            </Button>
            <Button size="large" style={{float:'left'}} 
              className={`navy-outline-button`}
              onClick={greenhouseDefaultLink}>
              Skip
            </Button>
          </Typography>
        </CardActions>
      </Card>
    </div>
  );
  const LoadingBody = (
    <div style={modalStyle} className={classes.paper}>
      <Card className={classes.card}>
        <CardContent>
          <CircularProgress />
        </CardContent>
      </Card>
    </div>
  )
  const modalContent = (isLoading) ? LoadingBody : ModalBody;
  const buttonBody = (
    <React.Fragment>
      <img 
        src={greenhouseIcon}
        className={classes.greenhouseIcon}
      />
        Greenhouse
    </React.Fragment>
  )
  const loadingButtonBody = (
    <CircularProgress style={{width: '24px',height: '24px'}}/>
  )
  const buttonContent = (props.buttonIsLoading) ? loadingButtonBody : buttonBody;
  return (
    <React.Fragment>
      <Button className={`${classes.greenhouseLink} greenhouse-button`} onClick={handleOpen} disabled={props.meetingStatus === "FAILED"}>
        {buttonContent}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalContent}
      </Modal> 
    </React.Fragment>
  );
}
