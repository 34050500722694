import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CandidateModalContent from './../IntegrationsPartners/Greenhouse/CandidateModalContent';
import CircularProgress from '@material-ui/core/CircularProgress';

import Yobs from './../../Services/Yobs';
import utils from './../utils';


const useStyles = makeStyles((theme) => ({
  root: {}
}));

export default function ConfirmGreenhouse(props) {
  const classes = useStyles();
  const [roleOptions, setRoleOptions] = React.useState([]);
  const [selectedRole, setSelectedRole] = React.useState(-1);  
  const nameOptions = utils.removeDuplicates(props.candidatesDetails.map( (candidate) => candidate.fullname ));
  const [selectedName, setSelectedName] = React.useState(nameOptions[0]);
  const [loading, setLoading] = React.useState(false);


  useEffect( async () => {
    const availableRoles = utils.removeDuplicates(props.candidatesDetails
      .filter((candidate) => candidate.fullname === nameOptions[0])
      .map((candidate) => candidate.rolename))
    setRoleOptions(availableRoles);
    setSelectedRole(0);
  }, []);

  const handleNameChange = (event) => {
    const name = event.target.textContent;
    setSelectedName(name);
    const availableRoles = utils.removeDuplicates(props.candidatesDetails
      .filter((candidate) => candidate.fullname === name)
      .map((candidate) => candidate.rolename))
    setRoleOptions(availableRoles);
    setSelectedRole(0);
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const confirmDetails = async () => {
    const candidates = props.candidatesDetails
      .filter( (candidate) => candidate.fullname === selectedName)
      .filter( (candidate) => candidate.rolename === roleOptions[selectedRole])

    if (candidates.length) {
      setLoading(true);
      const candidate = candidates[0];
      await Yobs.updateMeeting(props.meetingUuid, {
        greenhouseCandidate: {
          applicationId: candidate.applicationId,
          candidateId: candidate.candidateId
        }
      });
      props.onSuccess();
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <CandidateModalContent
        nameOptions={nameOptions}
        handleNameChange={handleNameChange}
        selectedRole={selectedRole}
        handleRoleChange={handleRoleChange}
        roleOptions={roleOptions}
        textColor={'black'}
      />
      <Button
        className={`salmon-button width-100`}
        style={{marginTop: 20}}
        onClick={confirmDetails}
      > 
        {
          (loading) ? (
            <CircularProgress/>
          ) : (
            <span> Confirm </span>
          )
        }
      </Button>
    </div>
  );
};

