import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import config from '../../config';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import ShareMeetingModal from './ShareMeetingModal';
import utils from './../utils';
import Yobs from './../../Services/Yobs';
import Greenhouse from './../../Services/Greenhouse';
import Lever from './../../Services/Lever';
import { makeStyles } from '@material-ui/core/styles';

import TabPanel from './../../Components/TabPanel';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GreenhouseCandidateModal from '../IntegrationsPartners/Greenhouse/GreenhouseCandidateModal';
import LeverOpportunityModal from '../IntegrationsPartners/Lever/LeverOpportunityModal';
import MuiAlert from '@material-ui/lab/Alert';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import MeetingInfoBar from './MeetingInfoBar';
import VideoContainer from './VideoContainer';
import ReportContainer from './ReportContainer';

const useStyles = makeStyles((theme) => ({
  copyLink: {
    float: 'right',
    margin: '15px 0px 0px 0px',
    padding: '5px 10px 5px 10px !important'
  },
  loaderContainer: {
    position: 'relative',
    top: '70px'
  },
  transcriptSegment: {
    display: 'flex',
    margin: '0px 0px 20px 0px'
  },
  transcriptIcon: {
    marginRight: '10px',
    position: 'relative',
    bottom: '2px'
  },
  infoBox: {
    width: '100%',
    padding: '20px',
    border: '1px solid rgba(0, 0, 0, 0.075)',
    maxWidth: '725px',
    textAlign: 'center'
  },
  tabContainer: {
    color: 'rgba(84, 84, 84) !important',
    backgroundColor: 'transparent !important',
    boxShadow: 'none !important',
    borderBottom: '1px solid rgba(200, 200, 200, 0.3)',
    margin: '0px 4px 30px 4px'
  },
  meetingTitle: {
    marginBottom: '5px'
  },
  resourceCenter: {
    width: '200px',
    backgroundColor: 'white',
    color: 'salmon',
    '&:hover': {
      backgroundColor: 'white !important',
      color: 'salmon !important'
    }
  }
}));

function MeetingPageContent({props}) {
  const { meetingId } = useParams();
  const classes = useStyles();
  const [meeting, setMeeting] = useState('loading');
  const [videoUrl, setVideoUrl] = useState(null);
  const [transcript, setTranscript] = useState(null);
  const [user, setUser] = useState(undefined);
  const [workspaceMembers, setWorkspaceMembers] = useState(undefined);
  const [comments, setComments] = useState('loading');
  const [teamSignupUrl, setTeamSignupUrl] = useState(undefined);
  const [isMeetingOwner, setIsMeetingOwner] = useState(undefined);

  const [greenhouseCandidatesDetails, setGreenhouseCandidatesDetails] = useState([]);
  const [leverOpportunitiesDetails, setleverOpportunitiesDetails] = useState([]);
  
  const [ghScoreCardUrl, setGhScoreCardUrl] = useState(undefined);
  const [leverOpportunityUrl, setLeverOpportunityUrl] = useState(undefined);
  
  const [ghLoading, setGhLoading] = useState(false);
  const [leverLoading, setLeverLoading] = useState(false);

  const [ghIntegrationAvailable, setGhIntegrationAvailable] = useState(false);
  const [leverIntegrationAvailable, setLeverIntegrationAvailable] = useState(false);


  const publicView = (props.publicKey && !meetingId) ? true : false;
  const reportUrl = (publicView) ? 
    `${config.getPublicMeetingReportUrl.replace('publicKey', props.publicKey)}` :
    `${config.getReportUrl.replace('meetingId', meetingId)}`;
  const pdfParams = {url: reportUrl, withCredentials: true};
  const pdfReport = React.useMemo( () => (pdfParams), [undefined]);

  const [snackbarText, setSnackbarText] = React.useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect( () => {
    if (publicView) {
      publicViewFetchData();
    } else {
      fetchData();
    }
  }, []);

  async function publicViewFetchData() {
    const data = await Yobs.getPublicMeeting(props.publicKey);
    setStateFromData(data);
    const comments = await Yobs.getPublicMeetingComments(props.publicKey);
    setComments(comments.object.comments);
  }
  async function fetchData() {
    const workspaceId = (await Yobs.getSettings()).object.selectedTeamId;
    const getGreenhouseIntegrationResponse = await Greenhouse.getGreenhouseIntegration(workspaceId);
    if (getGreenhouseIntegrationResponse === 200) {
      const receivedCandidatesDetails = await Greenhouse.getGreenhouseCandidates(workspaceId);
      const candidates = Greenhouse.populateCandidatesDetails(receivedCandidatesDetails.object);
      setGreenhouseCandidatesDetails(candidates);
      setGhIntegrationAvailable(true);
    }

    const getLeverIntegrationResponse = await Lever.getLeverIntegration(workspaceId);
    if (getLeverIntegrationResponse === 200) {
      const receivedCandidatesDetails = await Lever.getLeverOpportunities(workspaceId);
      const opportunities = Lever.populateCandidatesDetails(receivedCandidatesDetails.object);
      setleverOpportunitiesDetails(opportunities);
      setLeverIntegrationAvailable(true);
    }

    const data = await Yobs.getMeeting(meetingId);
    const getUserResponse = await Yobs.getUser();
    setUser(getUserResponse.object);
    setIsMeetingOwner(data.object.meeting.zoomUserId === getUserResponse.object.zoomUserId);
    setStateFromData(data);
    const workspaceMembers = await Yobs.getTeamMembers(workspaceId);
    setWorkspaceMembers(workspaceMembers.object.teamMembers);
    fetchComments();

    window.analytics.identify(getUserResponse.email, {
      name: getUserResponse.object.name,
      email: getUserResponse.object.email,
    });
    window.analytics.page();
  }
  async function fetchComments() {
    const data = await Yobs.getMeetingComments(meetingId);
    setComments(data.object.comments);
  }
  function setStateFromData(data) {
    setMeeting(data.object.meeting);
    setTeamSignupUrl(data.object.teamSignupUrl);
    if (data.object.videoUrls.length) {
      setVideoUrl(data.object.videoUrls[0]);
    }
    if (data.object.transcripts.length) {
      setTranscript(data.object.transcripts[0]);
    }
    if ('ghScoreCardUrl' in data.object) {
      setGhScoreCardUrl(data.object.ghScoreCardUrl)
    }

    if('leverOpportunityUrl' in data.object) {
      setLeverOpportunityUrl(data.object.leverOpportunityUrl);
    }

  }
  const handleSnackbarClose = (event, reason) => {
    setSnackbarText(undefined);
  };
  const copyLink = (publicKey) => {
    const publicUrl = `https://zoom.yobs.io/report/${publicKey}`;
    utils.copyTextToClipboard(publicUrl);
    setSnackbarText('Link has been copied to clipboard');
  }
  const copyMeetingInvitationUrl = () => {
    utils.copyTextToClipboard(teamSignupUrl);
    setSnackbarText('Link has been copied to clipboard');
  }
  const handleTabChange = (ev, newValue) => {
    setSelectedTab(newValue);
  };
  const goToResourceCenter = () => {
    window.open(config.resourceCenterLink, '_blank').focus();
  };


  if (meeting === 'loading') {
    return (
      <div className={classes.loaderContainer}>
        <Grid container spacing={12} className="main-content">
          <Grid item xs={12}>
              <CircularProgress />
          </Grid>
        </Grid>
      </div>
    );    
  }

  if (meeting.status === 'WAITING') {
    return (
      <div>
        <Grid container spacing={1} className="main-content">
          <Grid item xs={6}>
            <h1 className={classes.meetingTitle}> {meeting.topic} </h1>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <MeetingInfoBar meeting={meeting} />
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.infoBox}>
              <h4 className="text-navy"> This meeting is still waiting to be recorded. </h4>
            </Paper>            
          </Grid>
        </Grid>
      </div>      
    );
  }

  if (meeting.status === 'PROCESSING') {
    return (
      <div>
        <Grid container spacing={1} className="main-content">
          <Grid item xs={6}>
            <h1 className={classes.meetingTitle}> {meeting.topic} </h1>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <MeetingInfoBar meeting={meeting} />
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.infoBox}>
              <h4 className="text-navy" style={{marginTop: '10px'}}> Why is it processing? </h4>
              <div style={{width: '400px', margin: '0 auto'}}>
                <h4> You need to wait up to a couple of hours to get results. You will be notified by email when ready. </h4>
                <LinearProgress variant="determinate" value={33} style={{marginTop: '10px'}}/>
              </div>
            </Paper>            
          </Grid>
        </Grid>
      </div>      
    );    
  }

  if (meeting.status === 'FAILED' && !videoUrl) {
    return (
      <div>
        <Grid container spacing={1} className="main-content">
          <Grid item xs={6}>
            <h1 className={classes.meetingTitle}> {meeting.topic} </h1>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <MeetingInfoBar meeting={meeting} />
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.infoBox}>
              <h4 className="text-navy"> Meeting Failed To Process </h4>
              <a href='mailto:hello@yobstech.com'>
                <Button className='salmon-outline-button text-small'> Need more help? Email us </Button>
              </a>
            </Paper>
          </Grid>
        </Grid>
      </div>      
    );
  }

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackbarText}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarText}
      >
        <MuiAlert elevation={6} variant="filled" severity={'success'}>
          {snackbarText}
        </MuiAlert>
      </Snackbar>
      <Grid container spacing={1} className="main-content" style={{minWidth: 908}}>
        <Grid item xs={6}>
          <h1 className={`${classes.meetingTitle} text-navy`}>{meeting.topic} </h1>
        </Grid>
        <Grid item xs={6}>
          {
            !publicView && (
              <Button className={`${classes.copyLink} navy-button`} onClick={() => copyLink(meeting.publicKey)}> 
                <FileCopyIcon style={{marginRight: '10px'}}/>
                Copy share link 
              </Button>
            )
          }
          {
            (!publicView && isMeetingOwner) && (
              <ShareMeetingModal 
                meetingInvitationLink={teamSignupUrl}
                user={user}
                copyMeetingInvitationUrl={copyMeetingInvitationUrl}
                meetingId={meetingId}
                onShareComplete={() => setSnackbarText('Meeting has been shared')}
              />
            )
          }
          {
            !publicView && ghIntegrationAvailable && (
              <GreenhouseCandidateModal
                candidatesDetails={greenhouseCandidatesDetails}
                meetingUuid={meetingId}
                onSuccess={async () => {
                  setGhLoading(true);
                  const data = await Yobs.getMeeting(meetingId);
                  if ('ghScoreCardUrl' in data.object) {
                    window.open(data.object.ghScoreCardUrl, '_blank').focus();
                  }
                  setStateFromData(data);
                  setSnackbarText('Greenhouse candidate details have been confirmed');
                  setGhLoading(false);
                }}
                ghScoreCardUrl={ghScoreCardUrl}
                buttonIsLoading={ghLoading}
                meetingStatus={meeting.status}
              />
            )
          }
          {
            !publicView && leverIntegrationAvailable && (
              <LeverOpportunityModal
                opportunitiesDetails={leverOpportunitiesDetails}
                meetingUuid={meetingId}
                onSuccess={async () => {
                  setLeverLoading(true);
                  const data = await Yobs.getMeeting(meetingId);
                  if ('leverOpportunityUrl' in data.object) {
                    window.open(data.object.leverOpportunityUrl, '_blank').focus();
                  }
                  setStateFromData(data);
                  setSnackbarText('Lever candidate details have been confirmed');
                  setLeverLoading(false);
                }}
                leverOpportunityUrl={leverOpportunityUrl}
                buttonIsLoading={leverLoading}
                meetingStatus={meeting.status}
              />
            )
          }
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <MeetingInfoBar meeting={meeting} />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <AppBar position="static" className={classes.tabContainer}>
            <Tabs value={selectedTab} onChange={handleTabChange} aria-label="simple tabs example">
              <Tab label="Video/Transcript" />
              <Tab label="Yobs Report" disabled={meeting.status === "FAILED"}/>
            </Tabs>
          </AppBar>
          {
            (meeting.status === "FAILED") && (
              <Grid item xs={12} md={12} lg={12} style={{marginBottom: '10px'}}>
                <MuiAlert variant="filled" severity={'error'}
                  icon={<ErrorOutlineIcon/>}
                  action={
                    <Button color="inherit" size="small" className={classes.resourceCenter} onClick={goToResourceCenter}>
                      Go to resource center
                    </Button>
                  }
                >
                  Meeting recording failed to process
                </MuiAlert>
              </Grid>
            )
          }
          <TabPanel value={selectedTab} index={0}>
            <VideoContainer 
              comments={comments} 
              meetingId={meetingId} 
              videoUrl={videoUrl} 
              transcript={transcript} 
              onAddCommentSuccess={fetchComments}
              publicView={publicView}
              workspaceMembers={workspaceMembers}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <ReportContainer pdfReport={pdfReport} publicView={publicView} meetingId={meetingId}/>
          </TabPanel>     
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 240,
            }}
          >
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12} className="main-content-container">
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 240,
            }}
          >
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default function MeetingPage(props) {
  return <MeetingPageContent props={props}/>;
}
