import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import EventIcon from '@material-ui/icons/Event';
import ScheduleIcon from '@material-ui/icons/Schedule';

import utils from './../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: '725px',
    fontSize: '0.8rem',
    margin: '0px 0px 15px 0px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: 'black',
    backgroundColor: 'initial',
    padding: '0px',
    fontWeight: '800'
  },
  icon: {
    float: 'left',
    marginRight: '5px',
    position: 'relative',
    bottom: '3px'
  },
  emailRecipients: {
    overflowWrap: 'break-word'
  }
}));

export default function MeetingInfoBar(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        {
          props.meeting.emailRecipients && (
            <Grid item xs={'auto'}>
              <Paper className={`${classes.paper} ${classes.emailRecipients}`}>
                <MailOutlineIcon className={`${classes.icon} text-navy`}/>  
                {props.meeting.emailRecipients}
              </Paper>
            </Grid>
          )
        }
        <Grid item xs={'auto'}>
          <Paper className={classes.paper}>
            <WorkOutlineIcon className={`${classes.icon} text-navy`} /> 
            {utils.meetingFunctionMap(props.meeting.jobFunction)}
          </Paper>
        </Grid>
        <Grid item xs={'auto'}>
          <Paper className={classes.paper}>
            <InsertDriveFileOutlinedIcon className={`${classes.icon} text-navy`} />
            {utils.meetingRoleMap(props.meeting.jobRole)}
          </Paper>
        </Grid>
        {
          props.meeting.startTime &&
          <React.Fragment>
            <Grid item xs={'auto'}>
              <Paper className={classes.paper}>
                <EventIcon className={`${classes.icon} text-navy`}/> {utils.isoDateToHumanReadable(props.meeting.startTime)}
              </Paper>
            </Grid>
            <Grid item xs={'auto'}>
              <Paper className={classes.paper}>
                <ScheduleIcon className={`${classes.icon} text-navy`} /> {utils.isoDateToLocalTime(props.meeting.startTime)}
              </Paper>
            </Grid>
          </React.Fragment>
        }
      </Grid>
    </div>
  );
};

