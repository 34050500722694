import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import zoomLogo from './../../Assets/zoom-logo.png';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import utils from './../utils';

const useStyles = makeStyles((theme) => ({
  zoomIcon: {
    width: '40px',
    margin: '20px 0px 20px 10px'
  },
  tableRow: {
    border: '2px solid rgb(20 20 20 / 3%) !important',
    borderRadius: '5px',
    cursor: 'pointer',
    height: '100px',
    outline: '20px solid rgba(250,250,250,1) !important',
    margin: '10px',
    display: 'inline-block',
    backgroundColor: 'white',
    width: '100%',
    '&:hover, &:focus': {
      border: '2px solid rgb(20 20 20 / 5%) !important',
    }
  },
  zoomIconRow: {
    paddingRight: '0px !important'
  },
  rowText: {
    display: 'block',
    margin: '0px 0px 5px 0px'
  },
  rowTextBold: {
    fontFamily: 'PlusJakartaSans-Bold',
    display: 'block',
    margin: '0px 0px 5px 0px'    
  },
  tableContainer: {
    minWidth: '748px',
    backgroundColor: '#fafafa',
    position: 'relative',
    right: '10px',
    bottom: '10px'
  },
  floatRightCell: {
    float: 'right',
    position: 'relative',
    bottom: '70px'
  },
  roleCell: {
    padding: '0px 10px 0px 0px'
  },
  roleCellIcon: {
    position: 'relative',
    top: '6px',
    right: '2px'
  },
  COMPLETED: {
    backgroundColor: 'rgba(192, 238, 205, 1) !important',
    color: 'rgba(111, 205, 134, 1) !important'
  },
  FAILED: {
    backgroundColor: 'rgba(214, 152, 152, 1) !important',
    color: 'rgba(184, 85, 85, 1) !important'
  },
  PROCESSING: {
    backgroundColor: 'rgba(245, 216, 182, 1) !important',
    color: 'rgba(210, 147, 76, 1) !important'
  },
  WAITING: {
    backgroundColor: 'rgba(245, 216, 182, 1) !important',
    color: 'rgba(210, 147, 76, 1) !important'
  },
  statusButton: {
    borderRadius: '25px',
    width: '100px',
    textAlign: 'center',
    padding: '8px'
  },
  meetingTitle: {
    fontFamily: 'PlusJakartaSans-Bold'
  }
}));

export default function MeetingList(props) {
  const classes = useStyles();
  const history = useHistory();

  if (props.isLoading && props.data.length === 0) {
    return (
      <React.Fragment>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>          
                <CircularProgress />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </React.Fragment>
    );    
  }

  if (props.data.length === 0) {
    return (
      <React.Fragment>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>
                <h3 className="text-center"> No meetings have been processed by Yobs. </h3>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }


  const goTo = (routeName) => {
    history.push(routeName);
  };

  return (
    <React.Fragment>
      <Table size="small" className={classes.tableContainer}>
        <TableBody>
          {  props.data.map((meeting, index) => (
              <TableRow key={index} className={classes.tableRow} onClick={() => goTo(`/meeting/${encodeURIComponent(meeting.meetingUuid)}?teamId=${props.teamId}`)}>
                <TableCell key={index} className={classes.zoomIconRow}>
                  <img src={zoomLogo} className={classes.zoomIcon} alt="zoom-icon"/>
                </TableCell>
                <TableCell>
                  <span className={classes.meetingTitle}>
                    {meeting.topic}
                  </span>
                  <br />
                  <span className='text-gray'>
                  {utils.parseIsoDateTime(meeting.startTime, meeting.duration)}
                  </span>
                </TableCell>
                <TableCell className={`text-navy ${classes.roleCell} ${classes.floatRightCell}`}>
                  <div className={`${classes.statusButton} ${classes[meeting.status]}`} style={{ backgroundColor: 'transparent' }}>
                    {utils.capitalizeFirstLetter(meeting.status.toLowerCase())}
                  </div>
                </TableCell>
                <TableCell className={`text-navy ${classes.roleCell} ${classes.floatRightCell}`}>
                  <InsertDriveFileOutlinedIcon className={classes.roleCellIcon} />
                  <span className={'text-black'}> {utils.meetingRoleMap(meeting.jobRole)} </span>
                </TableCell>
                <TableCell className={`text-navy ${classes.roleCell} ${classes.floatRightCell}`}>
                  <WorkOutlineIcon className={classes.roleCellIcon} /> 
                  <span className={'text-black'}> {utils.meetingFunctionMap(meeting.jobFunction)} </span>
                </TableCell>
              </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
