import * as React from 'react';
import {
  NavLink
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import VideoCallIcon from '@material-ui/icons/VideoCall';

const useStyles = makeStyles((theme) => ({
  disabledNavButton: {
    cursor: 'initial !important',
    '&:hover': {
      backgroundColor: 'white !important'
    }
  },
  disabledSalmonButton: {
    cursor: 'initial !important',
    backgroundColor: 'rgba(255,251,251,1) !important',
    color: 'rgba(252, 205, 200, 1) !important',
    '&:hover': {
      backgroundColor: 'rgba(255,251,251,1) !important'
    }
  }
}));

export default function DisabledSideNav () {
  const classes = useStyles();
  return (
    <div>
      <div className="sideNavLink">
        <ListItem button disableRipple className={classes.disabledNavButton}>
          <ListItemIcon className="text-inherit">
            <DashboardIcon className="text-inherit"/>
          </ListItemIcon>
          <ListItemText primary="Overview" disableTypography={true} />
        </ListItem>
      </div>
      <div className="sideNavLink">
        <ListItem button disableRipple className={classes.disabledSalmonButton}>
          <ListItemIcon className="text-inherit">
            <VideoCallIcon className="text-inherit"/>
          </ListItemIcon>
          <ListItemText primary="Meetings" disableTypography={true} />
        </ListItem>
      </div> 
    </div>
  );
}

