import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from "react-router-dom";

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import Header from './Header';
import AddComment from './AddComment';
import ConfirmGreenhouse from './ConfirmGreenhouse';
import CommentList from './CommentList';
import MeetingEnded from './MeetingEnded';

import Yobs from './../../Services/Yobs';
import Greenhouse from './../../Services/Greenhouse';
import utils from './../utils';
import yobsLogo from './../../Assets/logo.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    border: '1px solid rgba(0,0,0,0.2)',
    height: 888,
    backgroundColor: '#fafafa',
    overflow: 'hidden'
  },
  yobsLogo: {
    paddingTop: 200,
    paddingBottom: 20
  },
  confirmGhHeader: {
    textAlign: 'center'
  },
  liveComments:{
    paddingLeft: 15
  },
  copyBtn: {
    marginTop: '15px !important',
    float: 'right'
  }
}));

//
// This component and page is used solely by the Yobs Chrome Extension
//

export default function LiveMeeting(props) {
  const classes = useStyles();
  const location = useLocation();
  const [user, setUser] = useState(undefined);
  const [meeting, setMeeting] = useState(undefined);
  const [meetingUuid, setMeetingUuid] = useState(undefined);
  const [comments, setComments] = useState([]);
  const [candidatesDetails, setGreenhouseCandidatesDetails] = useState([]);
  const [startedAt, setStartedAt] = useState('');
  const [meetingTimer, setMeetingTimer] = useState(0);
  const [workspaceMembers, setWorkspaceMembers] = useState(undefined);
  const [ghScoreCard, setGhScoreCard] = useState(undefined);
  const [snackbarText, setSnackbarText] = React.useState(false);
  const [workspaceId, setWorkspaceId] = React.useState(undefined);

  const [statusListener, setStatusListener] = useState(undefined);
  const [commentsListener, setCommentsListener] = useState(undefined);
  const [meetingStartedListener, setMeetingStartedListener] = useState(undefined);
  const [timerListener, setTimerListener] = useState(undefined);

  const [state, setState] = useState('1_loading');
  //
  // State is one of:
  // ['1_loading', '2_confirm-greenhouse', '3_wait-for-meeting-started',
  //  '4_live-meeting-feedback-tab', 'live-meeting-questions-tab', 
  //  '5_completed-meeting']
  //

  useEffect( async () => {
    const getUserResponse = await Yobs.getUser();
    setUser(getUserResponse.object);
    window.analytics.identify(getUserResponse.email, {
      name: getUserResponse.object.name,
      email: getUserResponse.object.email,
    });
    window.analytics.page();

    const meetingUuid = location.pathname.split('/')[2];
    const meetingData = await Yobs.getMeeting(meetingUuid);
    const workspaceId = (await Yobs.getSettings()).object.selectedTeamId;
    const workspaceMembers = await Yobs.getTeamMembers(workspaceId);
    const meetingComments = await Yobs.getMeetingComments(meetingUuid);

    setMeetingUuid(meetingUuid);
    setMeeting(meetingData.object.meeting);
    setWorkspaceId(workspaceId);
    setWorkspaceMembers(workspaceMembers.object.teamMembers);
    setComments(meetingComments.object.comments);

    const getGreenhouseIntegrationResponse = await Greenhouse.getGreenhouseIntegration(workspaceId);

    if (meetingData.object.ghScoreCardUrl) {
      setGhScoreCard(meetingData.object.ghScoreCardUrl);
      setState('3_wait-for-meeting-started');
    } else if (getGreenhouseIntegrationResponse === 400) {
      setState('3_wait-for-meeting-started');
    } else if (getGreenhouseIntegrationResponse === 200) {
      const receivedCandidatesDetails = await Greenhouse.getGreenhouseCandidates(workspaceId);
      const candidates = Greenhouse.populateCandidatesDetails(receivedCandidatesDetails.object);
      if (candidates.length) {
        setGreenhouseCandidatesDetails(candidates);
        setState('2_confirm-greenhouse');
      } else {
        setState('3_wait-for-meeting-started');
      }
    }
  }, []);


  useEffect(() => {
    //
    // watches for state changes
    //
    if (state === '3_wait-for-meeting-started') {
      waitForMeetingStarted();
    }
    if (state === '4_live-meeting-feedback-tab' && !statusListener && !commentsListener) {
      clearInterval(meetingStartedListener);
      setMeetingStartedListener(undefined);
      setupListeners();
    }
    if (state === '5_completed-meeting') {
      clearListeners();
    }
  }, [state]);



  const waitForMeetingStarted = async () => {
    //
    // waits for meeting to start. when it does, switch to in progress meeting UX
    //
    const listenForMeetingStarted = setInterval( async () => {
      const getMeetingStatus = await Yobs.getMeetingStatus(meetingUuid);
      if (getMeetingStatus.object.startedAt) {
        setStartedAt(getMeetingStatus.object.startedAt);
        setState('4_live-meeting-feedback-tab');
      };      
    },3000);
    setMeetingStartedListener(listenForMeetingStarted);
  };

  const setupListeners = () => {
    //
    // setup listeners to watch for new comments and for the meeting to end
    // also increments the meeting timer
    //
    const listenerForStatusUpdates = setInterval(refreshStatus, 3000);
    const listenerForComments = setInterval(refreshComments, 3000);
    const listenerForTimer = setInterval(refreshTimer, 1000);
    setStatusListener(listenerForStatusUpdates);
    setCommentsListener(listenerForComments);
    setTimerListener(listenerForTimer);
  };
  const clearListeners = () => {
    clearInterval(statusListener);
    clearInterval(commentsListener);
    clearInterval(timerListener);
    setStatusListener(undefined);
    setCommentsListener(undefined);
    setTimerListener(undefined);
  };

  const refreshComments = async () => {
    //
    // watches for new comments on this meeting, and refreshes comments
    //
    const meetingComments = await Yobs.getMeetingComments(meetingUuid);
    setComments(meetingComments.object.comments);
  };

  const refreshTimer = () => {
    //
    // increments meeting timer
    //
    const meetingStarted = new Date(startedAt);
    const timeNow = new Date();
    const timeDifference = (timeNow - meetingStarted) / 1000;
    setMeetingTimer(timeDifference);
  };

  const refreshStatus = async () => {
    //
    // watches for meeting to end. if meeting.endedAt exists,
    //  then go to the completed meeting UX.
    //
    const getMeetingStatus = await Yobs.getMeetingStatus(meetingUuid);
    if (getMeetingStatus.object.endedAt) {
      setState('5_completed-meeting');
    };
  };
  const copyComments = () => {
    const concatenated = [];
    comments.forEach( (comment) => {
      concatenated.push(`${comment.commenterName}\n`);
      concatenated.push(`${utils.processTimestamp(comment.timestampOfComment)} - ${comment.commentText}`);
      concatenated.push(`\n\n`);
    });
    concatenated.pop();
    utils.copyTextToClipboard(concatenated.join(''));
    setSnackbarText('Copied!')
  };
  const addComment = async (comment) => {
    await Yobs.createMeetingComment(meetingUuid, comment, String(meetingTimer));
    await refreshComments();
  };
  const getBodyContent = (state) => {
    if (state === '2_confirm-greenhouse') {
      return (
        <React.Fragment>
          <h4 className={classes.confirmGhHeader}> Confirm Candidate Details </h4>
          <ConfirmGreenhouse
            candidatesDetails={candidatesDetails}
            meetingUuid={meetingUuid}
            onSuccess={async () => {
              const meetingData = await Yobs.getMeeting(meetingUuid);
              setMeeting(meetingData.object.meeting);
              if (meetingData.object.ghScoreCardUrl) {
                setGhScoreCard(meetingData.object.ghScoreCardUrl);
              }              
              setState('3_wait-for-meeting-started');
            }}
          />
        </React.Fragment>
      );
    }
    if (state === '3_wait-for-meeting-started') {
      return (
        <div style={{marginTop: 40}}>
          <CircularProgress/>
          <h4 className={`text-center`}> Waiting for meeting to start </h4>
        </div>
      )
    }
    if (state === '4_live-meeting-feedback-tab') {
      return (
        <React.Fragment>
          <AddComment
            handleAddComment={addComment}
            startDateTime={startedAt}
            meetingTimer={utils.processTimestamp(meetingTimer)}
            workspaceMembers={workspaceMembers}
          />
          <CommentList
            comments={comments}
            workspaceMembers={workspaceMembers}
            height={'534px'}
          />
        </React.Fragment>      
      )
    }
    if (state === '5_completed-meeting') {
      return (
        <React.Fragment>
          <MeetingEnded 
            ghScoreCard={ghScoreCard}
            meetingUuid={meetingUuid}
            workspaceId={workspaceId}
          />
          <Grid container spacing={10} className={classes.topHeaderContainer}>
            <Grid item xs={6} md={6} lg={6}>
              <h4 className={classes.liveComments}> Live Comments </h4>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
              <Button className={`salmon-outline-button ${classes.copyBtn}`}
                onClick={copyComments}
              > 
                <FileCopyIcon/>
                Copy 
              </Button>
            </Grid>
          </Grid>
          <CommentList
            comments={comments}
            workspaceMembers={workspaceMembers}
            height={(ghScoreCard) ? '482px' : '586px'}
          />
        </React.Fragment>         
      )
    }
  };

  //
  // Meeting Controls HTML for local dev testing:
  // <hr />
  // <Button onClick={() => {
  //   setStartedAt('2022-02-16T20:48:31.042Z')
  //   setState('4_live-meeting-feedback-tab');
  // }}>
  //   Start Meeting
  // </Button>
  // <Button onClick={() => setState('5_completed-meeting')}>
  //   Complete Meeting
  // </Button>
  //
  //

  if (state === '1_loading') {
    return (
      <Container component="main" maxWidth="xs" className={classes.container} style={{textAlign: 'center'}}>
        <img src={yobsLogo} className={`width-50 ${classes.yobsLogo}`} alt="yobs-logo"/>
        <CircularProgress />
      </Container>
    )
  }
  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackbarText}
        autoHideDuration={2000}
        onClose={() => setSnackbarText(false)}
        message={snackbarText}
      >
        <MuiAlert elevation={6} variant="filled" severity={'success'}>
          {snackbarText}
        </MuiAlert>
      </Snackbar>      
      <Header 
        user={user}
        state={state}
        ghScoreCard={ghScoreCard}
      />
        {getBodyContent(state)}
    </Container>
  );

}