import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MeetingList from './MeetingList';
import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Yobs from './../../Services/Yobs';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Pagination from '@material-ui/lab/Pagination';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  filterButton: {
    float: 'right',
    margin: '20px 0px 0px 0px',
    backgroundColor: 'initial !important',
    color: 'black !important',
    boxShadow: 'none !important',
  },
  checkBoxContainer: {
    display: 'block',
    margin: '0px 10px 0px 10px'
  },
  progress: {
    position: 'relative !important',
    right: 10,
    bottom: 5,
    float: 'right'
  }
}));

function CompletedCallsContent() {
  const { selectedWorkspaceId } = useParams();
  const [meetings, setMeetings] = useState([]);
  const [meetingsCount, setMeetingsCount] = useState(0);
  const [personalMeetings, setPersonalMeetings] = useState([]);
  const [teamMemberMeetings, setTeamMemberMeetings] = useState([]);
  const [sharedWithMeetings, setSharedWithMeetings] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const pageSize = 5;

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFilterType, setSelectedFilterType] = useState('All');
  const classes = useStyles();

  useEffect( () => {
    async function fetchData() {
      const personal = await Yobs.getMeetings(undefined, 'completed-personal');
      setPersonalMeetings(personal.object.meetings);

      const teamMembers = (await Yobs.getTeamMembers(selectedWorkspaceId)).object.teamMembers;
      setTeamMembers(teamMembers);

      const getUserResponse = await Yobs.getUser();
      window.analytics.identify(getUserResponse.email, {
        name: getUserResponse.object.name,
        email: getUserResponse.object.email,
      });
      window.analytics.page();

      const teamMemberAccountIds = teamMembers.map( (member) => member.memberClientDashboardAccountId);
      if (!teamMemberAccountIds.includes(getUserResponse.object.yobsAccountId)) {
        window.location.href = window.location.origin;
      }

      const userRole = teamMembers
      .filter( (member) => member.memberClientDashboardAccountId === getUserResponse.object.yobsAccountId)[0].memberRole;

      const allPersonal = await fetchPersonalMeetingsLoop(personal.object.meetings, personal.object.next_page_token);
      const allTeamMeetings = await fetchTeamMeetingsLoop(userRole, teamMembers, getUserResponse.object.zoomUserId);

      setIsLoading(false);
    }
    fetchData();
  }, []);

  useEffect(() => {
    createMeetingList(personalMeetings, teamMemberMeetings, selectedFilterType);
  }, [selectedFilterType])

  useEffect(() => {
    createMeetingList(personalMeetings, teamMemberMeetings, selectedFilterType);
  }, [personalMeetings])

  useEffect(() => {
    createMeetingList(personalMeetings, teamMemberMeetings, selectedFilterType);
  }, [teamMemberMeetings])

  useEffect(() => {
    createMeetingList(personalMeetings, teamMemberMeetings, selectedFilterType);
  }, [page])


  const fetchPersonalMeetingsLoop = async (listOfMeetings, nextPageToken) => {
    if (nextPageToken === "") {
      setPersonalMeetings(listOfMeetings);
      return listOfMeetings;
    }
    const data = (await Yobs.getMeetings(nextPageToken, 'completed-personal')).object;
    const updatedMeetingList = [...listOfMeetings]
      .concat(data.meetings);
    return await fetchPersonalMeetingsLoop(updatedMeetingList, data.next_page_token);
  };

  const fetchSharedWithMeetingsLoop = async (listOfMeetings, nextPageToken) => {
    if (nextPageToken === "") {
      setSharedWithMeetings(listOfMeetings);
      return listOfMeetings;
    }
    const data = (await Yobs.getMeetings(nextPageToken, 'completed-shared')).object;
    const updatedMeetingList = [...listOfMeetings]
      .concat(data.meetings);
    return await fetchSharedWithMeetingsLoop(updatedMeetingList, data.next_page_token);
  };

  const fetchTeamMeetingsLoop = async (role, teamMembers, zoomUserId) => {
    if (role === 'member') {
      const sharedWithMeetings = await Yobs.getMeetings(undefined, 'completed-shared');
      const allSharedMeetings = await fetchSharedWithMeetingsLoop(sharedWithMeetings.object.meetings, sharedWithMeetings.object.next_page_token);
      setTeamMemberMeetings(allSharedMeetings);
    } else if (role === 'admin') {    
      let results = [];
      for (const member of teamMembers) {
        if (member.zoomUserId !== zoomUserId) {
          const teamMemberMeetings = await fetchAllMeetingsFromZoomUserId([], member.zoomUserId, undefined);
          results = results.concat(teamMemberMeetings);
          setTeamMemberMeetings(results);
        }
      }

      return results;
    }
  };

  const fetchAllMeetingsFromZoomUserId = async (listOfMeetings, zoomUserId, nextPageToken) => {
    if (nextPageToken === "") {
      return listOfMeetings;
    }
    const teamMemberMeetings = (await Yobs.getMeetingsByZoomUserId(nextPageToken, zoomUserId)).object;
    listOfMeetings = listOfMeetings.concat(teamMemberMeetings.meetings);
    return await fetchAllMeetingsFromZoomUserId(listOfMeetings, zoomUserId, teamMemberMeetings.next_page_token);
  }

  const createMeetingList = (personal, fromTeam, filterType) => {
    let meetingList;
    if (selectedFilterType === 'All') {
      meetingList = personal.concat(fromTeam);
    } else if (selectedFilterType === 'Recorded by me') {
      meetingList = personal;
    } else if (selectedFilterType === 'Shared with me') {
      meetingList = fromTeam;
    }
    const recurringMeetings = meetingList.filter( (meeting) => !meeting.startTime);
    const sortedMeetingList = meetingList.filter( (meeting) => meeting.startTime)
    .sort( (a,b) => {
      return new Date(b.startTime) - new Date(a.startTime)
    })
    .concat(recurringMeetings);
    const paginatedMeetingList = sortedMeetingList.slice(page*pageSize,(page*pageSize)+pageSize);
    setMeetings(paginatedMeetingList);
    setMeetingsCount(meetingList.length);
  }

  const shouldShowMoreButton = (meetings[0] !== 'loading')
  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setAnchorEl(null);
  };
  const handleFilterChecked = (ev) => {
    setPage(0);
    setSelectedFilterType(ev.target.name);
    // createMeetingList(personalMeetings, teamMemberMeetings, ev.target.name);
  };

  const handlePageChange = (event, value) => {
    setPage(value-1);
  };
  const open = Boolean(anchorEl);
  return (
    <div>
    <Grid container spacing={3} className="main-content">
      <Grid item xs={6} md={6} lg={6}>
        <h1 className="text-navy"> Completed Calls </h1>
      </Grid>
      <Grid item xs={6} md={6} lg={6}>
        <div>
          <Button className={classes.filterButton} variant="contained" color="primary" onClick={handleFilterClick}>
            <span> Filter: {selectedFilterType} <ExpandMoreIcon style={{position: 'relative', top: '7px'}}/> </span>
          </Button>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleFilterClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          > 
            <FormControlLabel
              className={classes.checkBoxContainer}
              control={
                <Checkbox
                  checked={selectedFilterType === 'All'}
                  onChange={handleFilterChecked}
                  name="All"
                />
              }
              label="All Meetings"
            />
            <FormControlLabel
              className={classes.checkBoxContainer}
              control={
                <Checkbox
                  checked={selectedFilterType === 'Recorded by me'}
                  onChange={handleFilterChecked}
                  name="Recorded by me"
                />
              }
              label="Recorded by me"
            />
            <FormControlLabel
              className={classes.checkBoxContainer}
              control={
                <Checkbox
                  checked={selectedFilterType === 'Shared with me'}
                  onChange={handleFilterChecked}
                  name="Shared with me"
                />
              }
              label="Shared with me"
            />
          </Popover>
        </div>
      </Grid>
      <Grid item xs={12} md={12} lg={12} className="main-content-container">
        <Paper
          className="background-initial"
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 240,
          }}
        >
          {
          <MeetingList data={meetings} isLoading={isLoading} teamId={selectedWorkspaceId}/>
          }
        </Paper>
        {
          shouldShowMoreButton && (
            <React.Fragment>
              <Pagination 
                count={Math.ceil(meetingsCount/pageSize)}
                page={page+1} 
                onChange={handlePageChange} 
                variant="outlined" 
                shape="rounded"
                siblingCount={1} boundaryCount={1} 
              />
              {
                ( isLoading && meetings.length > 0)  && (
                  <CircularProgress className={classes.progress}/>
                )
              }
            </React.Fragment>
          )
        }
      </Grid>
    </Grid>
    </div>
  );
}



export default function CompletedCalls() {
  return <CompletedCallsContent />;
}