import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import Yobs from '../../../Services/Yobs';
import CircularProgress from '@material-ui/core/CircularProgress';
import config from '../../../config';
import utils from '../../utils';

import TextField from '@material-ui/core/TextField';
import leverIcon from '../../../Assets/lever_rgb_mark_standard_inverted_transparent.png';

function leverDefaultLink() {
  const leverDefaultLink = config.leverDefaultLink;
  window.open(leverDefaultLink, '_blank').focus();
}


function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 550,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(0,0,0,0.1)',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '6px'
  },
  contactFullNameInput: {
    width: '100%',
    marginBottom: '20px'
  },

  contactFullNameInputHeader: {
    marginBottom: '5px'
  },
  
  jobPostingInput: {
    width: '100%',
    marginBottom: '20px'
  },

  jobPostingInputHeader: {
    marginBottom: '5px'
  },
  
  header: {
    marginTop: '0px'
  },
  divider: {
    width: '600px !important',
    position: 'relative',
    right: '50px'
  },
  card: {
    overflow: 'initial'
  },
  leverLink: {
    float: 'right',
    margin: '15px 7px 0px 0px',
    padding: '5px 10px 5px 10px !important'
  },
  leverIcon: {
    width: '20px',
    margin: '2px'
  },
  autocomplete: {
    "&$hover:hover": {
      borderColor: "salmon !important"
    }
  }
}));

export default function LeverOpportunityModal(props) {
  const classes = useStyles();
  
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const nameOptions = utils.removeDuplicates(props.opportunitiesDetails.map( (opportunity) => opportunity.contactName ));
  const [selectedName, setSelectedName] = React.useState('');

  const [roleOptions, setRoleOptions] = React.useState([]);
  const [selectedRole, setSelectedRole] = React.useState(-1);
  const [isLoading, setIsLoading] = React.useState(false);
  
  const handleOpen = () => {
    if (props.leverOpportunityUrl) {
      window.open(props.leverOpportunityUrl, '_blank').focus();
    } else {
      const availableRoles = utils.removeDuplicates(props.opportunitiesDetails
        .filter((opportunity) => opportunity.contactName === nameOptions[0])
        .map((opportunity) => opportunity.postName))
      setRoleOptions(availableRoles);
      setSelectedName(nameOptions[0]);
      setSelectedRole(0);    
      setOpen(true);
    }

  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleNameChange = (event) => {
    const name = event.target.textContent;
    setSelectedName(name);
    const availableRoles = utils.removeDuplicates(props.opportunitiesDetails
      .filter((opportunity) => opportunity.contactName === name)
      .map((opportunity) => opportunity.postName))
    setRoleOptions(availableRoles);
    setSelectedRole(0);
  };
  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };
  const onSubmitConfirm = async () => {
    const opportunities = props.opportunitiesDetails
      .filter( (opportunity) => opportunity.contactName === selectedName)
      .filter( (opportunity) => opportunity.postName === roleOptions[selectedRole])

    if (opportunities.length) {
      setIsLoading(true);
      const opportunity = opportunities[0];
      await Yobs.updateMeeting(props.meetingUuid, {
        leverOpportunity: {
          opportunityId: opportunity.opportunityId
        }
      });
      props.onSuccess();
      handleClose();
      setIsLoading(false);
    }
  };

  const ModalBody = (
    <div style={modalStyle} className={classes.paper}>
      <Card className={classes.card}>
        <CardContent>
            <h3 className={`text-navy ${classes.header}`}> Confirm Lever contact details
            </h3>
           <Divider className={classes.divider}/>
           <h4 className={`text-navy ${classes.contactFullNameInputHeader}`}> Contact full name:
           </h4>
          <FormControl variant="outlined" fullWidth className={classes.autocomplete}>
            <Autocomplete
              fullWidth
              options={nameOptions}
              onChange={handleNameChange}
              defaultValue={nameOptions[0]}
              className={classes.autocomplete}
              renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
            />
          </FormControl>
          <h4 className={`text-navy ${classes.jobPostingInputHeader}`}> Job posting: </h4>
          <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <Select
              displayEmpty={true}
              value={selectedRole}
              onChange={handleRoleChange}
            >
              {
                (roleOptions.length === 0) && (
                  <MenuItem value={0}> None found </MenuItem>
                )
              }
              {
                roleOptions.map( (name, index) => <MenuItem value={index} key={index}>{name}</MenuItem> )
              }
            </Select>
          </FormControl>
        </CardContent>
        <CardActions>
          <Typography style={{ flex: 1, padding: '0px 0px' }}>
            <Button size="large" style={{float:'right', margin:'5px'}} 
              className={`salmon-button`}
              onClick={onSubmitConfirm}>
                Confirm
            </Button>
            <Button size="large" style={{float:'left'}} 
              className={`navy-outline-button`}
              onClick={leverDefaultLink}>
              Skip
            </Button>
          </Typography>
        </CardActions>
      </Card>
    </div>
  );
  const LoadingBody = (
    <div style={modalStyle} className={classes.paper}>
      <Card className={classes.card}>
        <CardContent>
          <CircularProgress />
        </CardContent>
      </Card>
    </div>
  )
  const modalContent = (isLoading) ? LoadingBody : ModalBody;
  const buttonBody = (
    <React.Fragment>
      <img 
        src={leverIcon}
        className={classes.leverIcon}
      />
        Lever
    </React.Fragment>
  )
  const loadingButtonBody = (
    <CircularProgress style={{width: '24px',height: '24px'}}/>
  )
  const buttonContent = (props.buttonIsLoading) ? loadingButtonBody : buttonBody;
  return (
    <React.Fragment>
      <Button className={`${classes.leverLink} lever-button`} onClick={handleOpen} disabled={props.meetingStatus === "FAILED"}>
        {buttonContent}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalContent}
      </Modal> 
    </React.Fragment>
  );
}
