import fetch from 'node-fetch';
import config from '../config';

const setGreenhouseCandidate = async (teamId, meetingUuid, candidateId) => {
  let url = `${config.greenhouseUrl.replace('teamId', teamId)}`;
  const createResponse = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({})
  });
  validateResponseStatus(createResponse.status);
  const response = await createResponse.text();
  return response;
};

const getGreenhouseCandidates = async (teamId) => {
  let url = `${config.greenhouseCandidatesUrl.replace('teamId', teamId)}`;
  const invitation = await fetch(url, {
    credentials: 'include'
  });
  validateResponseStatus(invitation.status);
  const response = await invitation.json();
  return response;
}

const getGreenhouseIntegration = async (teamId) => {
  let url = `${config.greenhouseUrl.replace('teamId', teamId)}`;
  const invitation = await fetch(url, {
    credentials: 'include'
  });
  validateResponseStatus(invitation.status);
  return invitation.status;
}

const createGreenhouseApiKey = async (teamId, harvestApiKey) => {
  let url = `${config.greenhouseUrl.replace('teamId', teamId)}`;
  const createResponse = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({
      harvestApiKey
    })
  });
  validateResponseStatus(createResponse.status);
  const response = await createResponse.text();
  return createResponse.status;
};

const deleteGreenhouseApiKey = async (teamId) => {
  let url = `${config.greenhouseUrl.replace('teamId', teamId)}`;
  const createResponse = await fetch(url, {
    method: 'DELETE',
    credentials: 'include'
  });
  validateResponseStatus(createResponse.status);
  const response = await createResponse.text();
  return response;
};

const populateCandidatesDetails = (response) => {
  let candidatesDetails = [];
  response.forEach(candidate => {
    const fullname = `${candidate.first_name} ${candidate.last_name}`;
    const candidateId = candidate.id;
    
    let applicationId = '';
    const applications = candidate.applications;

    applications.forEach(application => {
      applicationId = application.id;
      const jobs = application.jobs;
      jobs.forEach(job => {
        candidatesDetails.push({
          'fullname': fullname,
          'candidateId': candidateId,
          'rolename': job.name,
          'applicationId': applicationId
        });
      })
    });
  });
  return candidatesDetails;
}

const validateResponseStatus = (statusCode) => {
  if (statusCode === 403 && process.env.NODE_ENV !== 'development') {
    window.location.href = config.loginUrl;
  }
};

const _exports = {
  populateCandidatesDetails,
  createGreenhouseApiKey,
  deleteGreenhouseApiKey,
  getGreenhouseCandidates,
  setGreenhouseCandidate,
  getGreenhouseIntegration
};
export default _exports;