import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import googleLogo from './../../Assets/googleicon.svg';
import googleButton from './../../Assets/btn_google_signin_dark_normal_web@2x.png';
import googleButtonHover from './../../Assets/btn_google_signin_dark_focus_web@2x.png';
import config from '../../config';
import yobsLogo from './../../Assets/logo.svg';
import googleIcon from './../../Assets/googleicon.svg';

const useStyles = makeStyles((theme) => ({
  signupButton: {
    margin: '10px 0px 30px 0px',
    width: '220px !important',
    fontWeight: 'bold',
    margin: '0 auto',
    display: 'block'
  },
  header: {
    marginTop: '20px',
    marginBottom: '0px'
  },
  signupContainer: {
    width: '400px !important',
    paddingTop: '50px'
  },
  signupLink: {
    margin: '10px 10px 30px 10px'
  },
  yobsLogo: {
    position: 'relative',
    right: '10px',
    width: '33%',
    marginBottom: '25px'
  },
  loginButton: {
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: 'salmon',
    margin: '0 auto',
    display: 'flex',
    width: '300px',
    '&:hover': {
      backgroundColor: '#e9967a'
    }
  },
  googleIcon: {
    width: '25px',
    margin: '10px'
  },
  link: {
    display: 'contents',
    color: 'rgba(22, 40, 92)',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

export default function Signup() {
  const classes = useStyles();

            // <img src={googleButton} className={classes.signupButton} alt="zoom-icon"
            //   onMouseOver={e => (e.currentTarget.src = googleButtonHover)}
            //   onMouseOut={e => (e.currentTarget.src = googleButton)} />
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        className={classes.signupContainer}
      >
        <img src={yobsLogo} className={`${classes.yobsLogo}`} alt="yobs-logo"/>
        <h1 className={`${classes.header} text-navy`}>
          Login to your account
        </h1>
        <h4 className='text-center'> Record, transcribe & share the best parts of your Zoom interview video calls. </h4>
          <a href={config.loginUrl} className={`width-100 ${classes.signupLink}`}>
            <Button className={`${classes.loginButton}`}> 
              <img 
                src={googleIcon}
                className={classes.googleIcon}
              />
              Login now with Google 
            </Button>
          </a>
          <Grid container className="text-center width-100 display-block">
            <Grid item>
            <h4 style={{marginTop: '-5px'}}> Don't have an account? 
              <Link href={config.loginUrl} variant="body2" className="text-center text-navy" style={{display: 'initial','marginLeft': '5px'}}>
                {"Sign up"}
              </Link>
              </h4>
            </Grid>
          </Grid>
          <h4 className='text-center' style={{'fontWeight':'initial'}}>
            By logging in or signing-up with Yobs, you agree with our 
            <a href='https://www.yobstech.com/terms-conditions' className={classes.link} target="_blank"> Terms & Conditions </a> and 
            <a href='https://www.yobstech.com/privacy-policy' className={classes.link} target="_blank"> Privacy Policy </a>.
          </h4>
      </Box>
    </Container>
  );

}