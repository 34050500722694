import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const bull = <span>•</span>;
const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 550,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(0,0,0,0.1)',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '6px'
  },
  changeAccessButton: {
    float: 'right',
    marginTop: '1px',
    minWidth: '100px'
  },
  emailInputHeader: {
    marginBottom: '5px'
  },
  inviteLinkSubHeader: {
    marginTop: '3px',
    marginBottom: '9px',
    color: 'rgba(84, 84, 84) !important'
  },
  header: {
    marginTop: '0px'
  },
  divider: {
    width: '600px !important',
    position: 'relative',
    right: '50px'
  },
  card: {
    overflow: 'initial'
  },
  saveBtn: {
    width: 'calc(50% - 10px)',
    margin: '0px 5px 0px 5px !important',
    display: 'inline'
  },
  cardHeader: {
    padding: '0px',
    height: '88px'
  },
  form: {
    position: 'relative',
    top: '24px'
  }
}));

export default function ChangeAccessModal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [modalTab, setModalTab] =React.useState(1);
  const [accessLevel, setAccessLevel] = React.useState(props.member.memberRole);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (ev) => {
    setAccessLevel(ev.target.value);
  };
  const LoadingBody = (
    <div style={modalStyle} className={classes.paper}>
      <Card className={classes.card}>
        <CardContent>
          <CircularProgress />
        </CardContent>
      </Card>    
    </div>
  )
  const ModalBody = (
    <div style={modalStyle} className={classes.paper}>
      <Card className={classes.card}>
        <CardContent>
            <h3 className={`text-navy ${classes.header}`}> Change Access
            </h3>
           <Divider className={classes.divider}/>
          <h4 className={`${classes.emailInputHeader}`}>
            Change access level
          </h4>
          <h5 className={classes.inviteLinkSubHeader}>
            Anyone with link can join as a standard member
          </h5>

          <CardHeader
            avatar={
              <Avatar aria-label="recipe" >
                {props.member.memberName.slice(0,1).toUpperCase()}
              </Avatar>
            }
            title={props.member.memberName}
            subheader={props.member.email}
            className={classes.cardHeader}
            action={
              <React.Fragment>
                <FormControl variant="outlined" className={classes.form}>
                  <Select
                    value={accessLevel}
                    onChange={handleChange}
                  >
                    <MenuItem value={"member"}>Member</MenuItem>
                    <MenuItem value={"admin"}>Admin</MenuItem>
                  </Select>
                </FormControl>
              </React.Fragment>
            }            
          />
        </CardContent>
        <CardActions>
          <Typography style={{ flex: 1 }}>
            <Button size="small" className={`${classes.saveBtn} width-50 navy-outline-button`}
              onClick={handleClose}>
              Cancel
            </Button>
            <Button size="small" className={`${classes.saveBtn} width-50 salmon-button`}
              onClick={async () => {
                setIsLoading(true);
                await props.onUpdateAccess(props.member.teamId, props.member.memberClientDashboardAccountId, accessLevel);
                setIsLoading(false);
                handleClose();
              }}>
              Save
            </Button>
          </Typography>
        </CardActions>
      </Card>
    </div>
  );

  const modalContent = (isLoading) ? LoadingBody : ModalBody;
  return (
    <React.Fragment>
      <Button 
        className={`salmon-outline-button float-right ${classes.changeAccessButton}`} 
        onClick={handleOpen}
        disabled={props.disabled}
      > 
        Change Access
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalContent}
      </Modal> 
    </React.Fragment>
  );
}
