import React, { useState, useEffect } from 'react';
import {
  NavLink
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Yobs from './../Services/Yobs';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 600,
    margin: '0 auto',
    position: 'relative',
    top: 100,
    textAlign: 'center'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardContainer: {
    backgroundColor: 'rgba(249,249,249,1)',
    width: '100vw',
    height: '100vh'
  },
  square: {
    margin: '0 auto',
    backgroundColor: 'rgba(39, 70, 248, 0.5)',
    borderRadius: '10px'
  },
  text: {
    margin: '20px auto 0',
    width: '400px !important',
    textAlign: 'center'
  },
}));

export default function CreateWorkspace(props)  {
  const classes = useStyles();

  return (
    <div className={classes.cardContainer}>
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <h3 className="text-navy"> Create your workspace </h3>
          <h5> We suggest using the name of your work. </h5>
          <Avatar variant="square" className={classes.square}>
            {props.workspaceName.slice(0,1).toUpperCase()}
          </Avatar>
          <h5 className={classes.text}>
            <TextField fullWidth placeholder="Enter workspace name" variant="outlined" size="small"
              value={props.workspaceName}
              onChange={props.handleWorkspaceNameChange}
            />
          </h5>
          <h5 className={`${classes.text}`}>
            <Button disabled={props.workspaceName.length === 0} size="small" fullWidth className='salmon-button'
              onClick={props.createWorkspace}>
              Create workspace
            </Button>
          </h5>
        </CardContent>
      </Card>
    </div>
  );

}

