import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Switch from '@material-ui/core/Switch';
import Pagination from '@material-ui/lab/Pagination';
import Settings from './Settings';
import UpcomingCallContainer from './UpcomingCallContainer';
import Yobs from './../../Services/Yobs';
import { makeStyles } from '@material-ui/core/styles';
import zoomLogo from './../../Assets/zoom-logo.png';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  zoomIcon: {
    width: '40px',
    margin: '20px 0px 20px 10px'
  },
  tableRow: {
    border: '2px solid rgb(20 20 20 / 3%) !important',
    borderRadius: '5px',
    height: '100px',
    outline: '20px solid rgba(250,250,250,1) !important',
    margin: '10px',
    display: 'inline-block',
    backgroundColor: 'white',
    width: '100%'
  },
  zoomIconRow: {
    paddingRight: '0px !important'
  },
  settingsRow: {
    width: '100%',
    paddingRight: '0px !important',
    display: 'block'
  },
  rowText: {
    display: 'block',
    margin: '0px 0px 5px 0px',
    color: 'rgba(84, 84, 84) !important'
  },
  rowTextBold: {
    fontFamily: 'PlusJakartaSans-Bold',
    display: 'block',
    margin: '0px 0px 5px 0px'    
  },
  floatRightCell: {
    float: 'right',
    position: 'relative',
    bottom: '75px',
    paddingRight: '75px !important'
  },
  grayBlank: {
    width: '150px',
    backgroundColor: 'rgba(200, 200, 200)',
    height: '10px',
    borderRadius: '25px',
    marginBottom: '10px'
  },
  grayBlank2: {
    width: '125px',
    backgroundColor: 'rgba(200, 200, 200)',
    height: '10px',
    borderRadius: '25px'
  },
  grayBlank3: {
    width: '100px',
    backgroundColor: 'rgba(200, 200, 200)',
    height: '10px',
    borderRadius: '25px',
    display: 'inline-block'
  },
}));

function UpcomingCallsContent() {
  const classes = useStyles();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [meetingList, setMeetingList] = useState([]);
  const [recurringMeetingList, setRecurringMeetingList] = useState([]);
  const [settings, setSettings] = useState({});
  const [user, setUser] = useState({});
  const [snackbarText, setSnackbarText] = React.useState('');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [meetingPage, setMeetingPage] = useState(0);
  const [recurringMeetingPage, setRecurringMeetingPage] = useState(0);
  const recurringMeetingIdMap = {};
  const pageSize = 4;

  useEffect( () => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const data = await Promise.all([
      Yobs.getMeetings(),
      Yobs.getSettings(),
      Yobs.getUser()
    ]);
    const getMeetingsResponse = data[0].object;
    
    const meetings = getMeetingsResponse.meetings;
    const recurringMeetings = preprocessRecurringMeetings(getMeetingsResponse.recurringMeetings);
    setMeetingList(meetings);
    setRecurringMeetingList(recurringMeetings);

    setIsProcessing(false);

    const getSettingsResponse = data[1].object;
    setSettings(getSettingsResponse);

    const getUserResponse = data[2].object;
    setUser(getUserResponse);

    window.analytics.identify(getUserResponse.email, {
      name: getUserResponse.name,
      email: getUserResponse.email,
    });
    window.analytics.page();
    setIsPageLoading(false);
    await fetchMeetingsLoop(meetings, recurringMeetings, getMeetingsResponse.next_page_token);
  }

  const fetchMeetingsLoop = async (listOfAllMeetings, listOfAllRecurringMeetings, nextPageToken) => {
    if (nextPageToken === "") {
      return;
    }
    const data = (await Yobs.getMeetings(nextPageToken)).object;

    const updatedMeetingList = [...listOfAllMeetings]
      .concat(data.meetings);
    const updatedRecurringMeetingList = [...listOfAllRecurringMeetings]
      .concat(preprocessRecurringMeetings(data.recurringMeetings));

    setMeetingList(updatedMeetingList);
    setRecurringMeetingList(updatedRecurringMeetingList);

    return await fetchMeetingsLoop(updatedMeetingList, updatedRecurringMeetingList, data.next_page_token);
  };

  const preprocessRecurringMeetings = (recurringMeetingList) => {
    const response = recurringMeetingList.filter( (meeting) => {
      if (!recurringMeetingIdMap[encodeURIComponent(meeting.id)]) {
        recurringMeetingIdMap[encodeURIComponent(meeting.id)] = true;
        return true;
      } else {
        return false;
      }
    });
    return response
  };

  const snackbarAlerts = () => {
    const handleClose = (event, reason) => {
      setSnackbarOpen(false);
    };
    return (
      <React.Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          autoHideDuration={6000}
          open={snackbarOpen}
          onClose={handleClose}
          message={snackbarText}
        >
          <MuiAlert elevation={6} variant="filled" severity={snackbarSeverity}>
            {snackbarText}
          </MuiAlert>
        </Snackbar>
      </React.Fragment>
    )
  }

  const EmptyState = (
    <React.Fragment>
      <Table size="small">
        <h1 className="text-navy"> Upcoming calls </h1>
        <TableBody>
          <TableRow>
            <TableCell>
              <React.Fragment>
                <TableRow className={`${classes.tableRow}`}>
                  <TableCell className={classes.zoomIconRow}>
                    <img src={zoomLogo} className={classes.zoomIcon} alt="zoom-icon"/>
                  </TableCell>
                  <TableCell>
                    <span className={classes.rowTextBold}>
                      <div className={classes.grayBlank} />
                    </span>
                    <span className={classes.rowText}>
                      <div className={classes.grayBlank2} />
                    </span>
                  </TableCell>
                  <TableCell className={classes.floatRightCell}>
                    <Switch
                      size="medium"
                      checked={false}
                      disabled
                    />
                    <span > 
                      <div className={classes.grayBlank3} />
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow >
                  <TableCell className={classes.settingsRow}>
                  </TableCell>
                </TableRow>
              </React.Fragment>
              <h3 className="text-center text-navy" style={{marginTop: '0px'}}> No upcoming calls </h3>
              <h4 className="text-center"> You need to have Zoom meetings scheduled, or add mia@yobs.io to a meeting. </h4>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </React.Fragment>
  );
  const toggleYobsProcessing_upcoming = async (switchStateBoolean, meetingId, index, params) => {
    if (isProcessing) {
      return;
    }
    if (switchStateBoolean) {
      if ([params.meetingType, params.jobFunction, params.jobRole].includes('')) {
        setSnackbarSeverity('error');
        setSnackbarText('Missing required information');
        setSnackbarOpen(true);
        setIsProcessing(false);
        return;
      }
      setIsProcessing(true);
      const newMeetings = [...meetingList];
      newMeetings[(meetingPage*pageSize) + index].SHOULD_PROCESS = switchStateBoolean;
      setMeetingList(newMeetings);
      await Yobs.enableProcessing(meetingId, params);
    } else {
      setIsProcessing(true);
      const newMeetings = [...meetingList];
      newMeetings[(meetingPage*pageSize) + index].SHOULD_PROCESS = switchStateBoolean;
      setMeetingList(newMeetings);      
      await Yobs.disableProcessing(meetingId);
    }
    setSnackbarSeverity('success')
    setSnackbarText('Call settings updated successfully');
    setSnackbarOpen(true);
    setIsProcessing(false);
  };
  const toggleYobsProcessing_recurring = async (switchStateBoolean, meetingId, index, params) => {
    if (isProcessing) {
      return;
    }
    if (switchStateBoolean) {
      if ([params.meetingType, params.jobFunction, params.jobRole].includes('')) {
        setSnackbarSeverity('error')
        setSnackbarText('Missing required information');
        setSnackbarOpen(true);
        setIsProcessing(false);
        return;
      }
      setIsProcessing(true);
      const newRecurringMeetings = [...recurringMeetingList];
      newRecurringMeetings[(recurringMeetingPage * pageSize) + index].SHOULD_PROCESS = switchStateBoolean;
      setRecurringMeetingList(newRecurringMeetings);
      await Yobs.enableProcessing(meetingId, params);
    } else {
      setIsProcessing(true);
      const newRecurringMeetings = [...recurringMeetingList];
      newRecurringMeetings[(recurringMeetingPage * pageSize) + index].SHOULD_PROCESS = switchStateBoolean;
      setRecurringMeetingList(newRecurringMeetings);      
      await Yobs.disableProcessing(meetingId);
    }
    setSnackbarSeverity('success')
    setSnackbarText('Call settings updated successfully');
    setSnackbarOpen(true);
    setIsProcessing(false);
  };

  const onSettingsChange = async (RECORD_ALL, SHARE_ALL, EMAIL_NOTIFICATION, VIDEO_LANGUAGE) => {
    setIsProcessing(true);
    const newSettings = {};
    if (typeof(RECORD_ALL) === 'boolean') {
      newSettings.RECORD_ALL = RECORD_ALL;
    }
    if (typeof(SHARE_ALL) === 'boolean') {
      newSettings.SHARE_ALL = SHARE_ALL;
    }
    if (typeof(EMAIL_NOTIFICATION) === 'boolean') {
      newSettings.EMAIL_NOTIFICATION = EMAIL_NOTIFICATION;
    }
    if (VIDEO_LANGUAGE) {
      newSettings.VIDEO_LANGUAGE = VIDEO_LANGUAGE;
    }
    const newSettingState = Object.assign({}, settings, newSettings);
    setSettings(newSettingState);
    await Yobs.updateSettings(newSettings);
    await fetchData();
    setSnackbarSeverity('success')
    setSnackbarText('Account settings updated successfully');
    setSnackbarOpen(true);
  };
  const shouldShowPaginationUI = (isPageLoading === false && meetingList.length > 0);
  const shouldShowRecurringPaginationUI = (isPageLoading === false && recurringMeetingList.length > 0);

  const handlePageChange = (event, value) => {
    setMeetingPage(value-1);
  };
  const handleRecurringPageChange = (event, value) => {
    setRecurringMeetingPage(value-1);
  };  
  return (
    <div>
      { snackbarAlerts() }
      <Grid container spacing={3} className="main-content">
        <Grid item xs={12} md={7} lg={8} className="main-content-container">
          <Paper className="background-initial"
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 240,
            }}
          >
            {
              isPageLoading && (
                <React.Fragment>
                  <h1 className="text-navy"> Upcoming calls </h1> 
                  <CircularProgress />
                </React.Fragment>                
              )   
            }
            { 
              (isPageLoading === false && meetingList.length === 0 && recurringMeetingList.length === 0) && (
                <React.Fragment>
                {EmptyState}
                </React.Fragment>
              )
            }
            {
              meetingList.length > 0 && (
                <React.Fragment>
                  <h1 className="text-navy"> Upcoming calls </h1>
                  <UpcomingCallContainer 
                    data={meetingList.slice(meetingPage*pageSize,(meetingPage*pageSize)+pageSize)} 
                    toggleYobsProcessing={toggleYobsProcessing_upcoming}
                  />
                  {
                    shouldShowPaginationUI && (
                      <Pagination 
                        count={Math.ceil(meetingList.length/pageSize)}
                        page={meetingPage+1} 
                        onChange={handlePageChange} 
                        variant="outlined" 
                        shape="rounded"
                        siblingCount={1} boundaryCount={1} 
                      />
                    )
                  }
                </React.Fragment>
              ) 
            }          
            {
              recurringMeetingList.length > 0 && (
                <React.Fragment>
                  <h1 className="text-navy"> Recurring Calls </h1>
                  <UpcomingCallContainer
                    data={recurringMeetingList.slice(recurringMeetingPage*pageSize,(recurringMeetingPage*pageSize)+pageSize)} 
                    toggleYobsProcessing={toggleYobsProcessing_recurring}
                  />
                  {
                    shouldShowRecurringPaginationUI && (
                      <Pagination 
                        count={Math.ceil(recurringMeetingList.length/pageSize)}
                        page={recurringMeetingPage+1} 
                        onChange={handleRecurringPageChange} 
                        variant="outlined" 
                        shape="rounded"
                        siblingCount={1} boundaryCount={1} 
                      />
                    )
                  }                  
                </React.Fragment>
              ) 
            }
          </Paper>
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 240,
            }}
          >
            <Settings data={settings} onChange={onSettingsChange} user={user} isProcessing={isProcessing}/>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default function UpcomingCalls() {
  return <UpcomingCallsContent />;
}