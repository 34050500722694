import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import MessageIcon from '@material-ui/icons/Message';

import utils from './../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'scroll'    
  },
  timestampComment: {
    display: 'contents',
    textDecoration: 'underline'
  }
}));

export default function CommentList(props) {
  const classes = useStyles();
  const sortedComments = props.comments.sort( (a,b) => {
    return (new Date(b.createdAt)) - (new Date(a.createdAt))
  });
  const processedComments = sortedComments.map( (comment) => {
    return Object.assign(comment, {commentText: utils.parseCommentForMentions(comment.commentText, props.workspaceMembers)});
  });

  return (
    <div className={classes.root} style={{height: props.height}}>
     {
       processedComments.length === 0 && (
          <Grid item xs={12} md={12} fullWidth style={{margin: '40px', textAlign: 'center'}}>
            <div>
              <MessageIcon style={{fontSize: '50px'}}/>
              <h3> No comments yet </h3>
            </div>
          </Grid>
       )
     }    
     {
       processedComments.map( (comment, index) => {
        return (
          <Grid item xs={12} md={12} style={{marginBottom: '20px'}} key={index}>
            <div>
              <List dense={false}>
                <ListItem >
                  <ListItemAvatar>
                    <Avatar src={comment.picture}>
                      {comment.commenterName.slice(0,1).toUpperCase()}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={<b>{comment.commenterName}</b>}
                  />
                  <ListItemSecondaryAction>
                    <b>{utils.timeDifference(Date.now(), new Date(comment.createdAt))}</b>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
              <List dense={false}>
                <ListItem>
                  <ListItemText
                    primary={
                      <React.Fragment>
                      <span>
                        <div className={classes.timestampComment}>
                          <u> {utils.processTimestamp(comment.timestampOfComment)}</u>
                        </div> - {comment.commentText}
                        </span>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            </div>
          </Grid>
        )
       })
     }
    </div>
  );
};

