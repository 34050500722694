import * as React from 'react';
import config from '../config';
import { styled } from '@material-ui/core/styles';
import MuiAppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import {
  Link
} from "react-router-dom";

const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  }),
}));


export default function DisabledTopNav(props)  {
  const signup = () => {
    if (typeof window !== 'undefined') {
       window.location.href = config.loginUrl;
    }
  };  
  const login = () => {
    if (typeof window !== 'undefined') {
       window.location.replace(config.dashboardUrl);
    }
  };
  return (
    <AppBar position="absolute" open={props.open} className="text-gray background-white border-light">
      <Toolbar
        sx={{
          pr: '24px',
        }}
      >
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item>
          </Grid>
          <Grid item>
            <div>
              <Button className='salmon-outline-button' onClick={() => signup()}>Sign-up</Button>
              <Button className='salmon-button' onClick={() => login()}>Login</Button>
            </div>
          </Grid>
        </Grid>    
      </Toolbar>
    </AppBar>
  );
}
