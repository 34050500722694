import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import config from '../../../config';

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 550,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(0,0,0,0.1)',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '6px'
  },
  addleverPartnerButton: {
    float: 'right',
    marginTop: '25px',
    minWidth: '185px'
  },
  leverOauthSubHeader: {
    marginTop: '0px',
    marginBottom: '20px',
    color: 'rgba(175, 175, 175) !important'
  },
  header: {
    marginTop: '0px'
  },
  divider: {
    width: '600px !important',
    position: 'relative',
    right: '50px'
  },
  card: {
    overflow: 'initial'
  },
  button: {
    margin: '0px',
    width: 'calc(50% - 5px)'
  },
  cancelButton: {
    margin: '0px 10px 0px 0px !important'
  }
}));

export default function LeverDisconnectModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const ModalBody = (
    <div style={modalStyle} className={classes.paper}>
      <Card className={classes.card}>
        <CardContent>
          <h3 className={`text-navy ${classes.header}`}> Are you sure you want to disconnect Lever?
          </h3>
          <h4 className={classes.leverHarvestApiKeySubHeader}>
            Any features related to this integration will not be available anymore. You can re-connect this integration at any time.
          </h4> 
          <Divider className={classes.divider}/>
        </CardContent>
        <CardActions>
          <Typography style={{ flex: 1, padding: '0px 7px' }}>
            <Button  
              className={`navy-outline-button ${classes.button} ${classes.cancelButton}`}
              onClick={props.handleClose}>
              Cancel
            </Button>
            <Button
              className={`${classes.button} salmon-button`}
              onClick={() => props.onSubmit()}>
                Disconnect
            </Button>
          </Typography>
        </CardActions>
      </Card>
    </div>
  );
  const LoadingBody = (
    <div style={modalStyle} className={classes.paper}>
      <Card className={classes.card}>
        <CardContent>
          <CircularProgress />
        </CardContent>
      </Card>
    </div>
  )
  const modalContent = (props.isLoading) ? LoadingBody : ModalBody;
  return (
    <React.Fragment>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalContent}
      </Modal> 
    </React.Fragment>
  );
}
