import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ChipInput from 'material-ui-chip-input'

import utils from './../utils';


function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const bull = <span>•</span>;
const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 550,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(0,0,0,0.1)',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '6px'
  },
  inviteButton: {
    float: 'right',
    marginTop: '25px',
    minWidth: '185px'
  },
  emailInput: {
    width: '100%',
    marginBottom: '20px'
  },
  inviteLinkInput: {
    width: '310px',
    marginRight: '5px'
  },
  emailInputHeader: {
    marginBottom: '5px'
  },
  inviteLinkHeader: {
    marginTop: '0px',
    marginBottom: '0px'
  },
  inviteLinkSubHeader: {
    marginTop: '3px',
    marginBottom: '9px',
    color: 'rgba(84, 84, 84) !important'
  },
  copyLink: {
    height: '55px'
  },
  header: {
    marginTop: '0px'
  },
  divider: {
    width: '600px !important',
    position: 'relative',
    right: '50px'
  },
  card: {
    overflow: 'initial'
  },
  chipInput: {
    marginBottom: 20
  }
}));

export default function InviteModal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [modalTab, setModalTab] =React.useState(1);
  const [emailChips, setEmailChips] = React.useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const copyLink = () => {
    utils.copyTextToClipboard(props.invitationLink);
    props.setSnackbarOpen();
  }
  const onAddEmailChip = (chip) => {
    if (utils.validateEmail(chip)) {
      const newState = [...emailChips];
      newState.push(chip)
      setEmailChips(newState);
    }
  };
  const onDeleteEmailChip = (chip, index) => {
    const newState = [...emailChips]
    newState.splice(index,1);
    setEmailChips(newState);
  };
  const onSubmitInvitations = async () => {
    await props.handleInviteEmailAddresses(emailChips);
    setEmailChips([]);
    handleClose();
  };
  const ModalBody = (
    <div style={modalStyle} className={classes.paper}>
      <Card className={classes.card}>
        <CardContent>
            <h3 className={`text-navy ${classes.header}`}> Invite a Team member to your workspace
            </h3>
           <Divider className={classes.divider}/>
          <h4 className={`${classes.emailInputHeader} text-navy`}>
            Email
          </h4>

          <ChipInput
            fullWidth
            placeholder='Enter email addresses to invite'
            variant='outlined'
            value={emailChips}
            onAdd={(chip) => onAddEmailChip(chip)}
            onDelete={(chip, index) => onDeleteEmailChip(chip, index)}
            className={classes.chipInput}
          />
          <h4 className={`${classes.inviteLinkHeader} text-navy`}>
            Invite with link
          </h4>
          <h4 className={classes.inviteLinkSubHeader}>
            Anyone with link can join as a standard member
          </h4>                    
          <TextField
            id="standard-helperText"
            value={props.invitationLink}
            color="salmon"
            className={classes.inviteLinkInput}
            disabled
            variant="outlined"
          />
          <Button className={`${classes.copyLink} navy-button`} onClick={copyLink}>
            <FileCopyIcon style={{marginRight: 5}}/>
            Copy link
          </Button>
        </CardContent>
        <CardActions>
          <Typography style={{ flex: 1, padding: '0px 7px' }}>
            <Button size="small" style={{float:'right'}} 
              className={`salmon-button`}
              onClick={onSubmitInvitations}>
                Send Invite
            </Button>
            <Button size="small" style={{float:'right'}} 
              className={`navy-outline-button`}
              onClick={handleClose}>
            Cancel
            </Button>
          </Typography>
        </CardActions>
      </Card>
    </div>
  );

  return (
    <React.Fragment>
      <Button className={`salmon-button float-right ${classes.inviteButton}`} onClick={handleOpen}> 
        Invite team member 
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {ModalBody}
      </Modal> 
    </React.Fragment>
  );
}
