import fetch from 'node-fetch';
import config from '../config';

const deleteLeverOauth = async (teamId) => {
  let url = `${config.leverUrl.replace('teamId', teamId)}`;
  const deleteResponse = await fetch(url, {
    method: 'DELETE',
    credentials: 'include'
  });
  validateResponseStatus(deleteResponse.status);
  return deleteResponse.status;
};

const validateResponseStatus = (statusCode) => {
  if (statusCode === 403 && process.env.NODE_ENV !== 'development') {
    window.location.href = config.loginUrl;
  }
};

const populateCandidatesDetails = (response) => {
  let candidatesDetails = [];
  const postings = response['postings'];
  response['opportunities'].forEach(opportunity => {
    const contactName = opportunity.name;
    const opportunityId = opportunity.id;
    
    const applications = opportunity.applications;
    
    applications.forEach(application => {
      const postingId = application.id;
      const posting = application.posting;
      const postname = postings.find(post => post.id === posting);
      if (postname && 'text' in postname) {
        candidatesDetails.push({
          'contactName': contactName,
          'opportunityId': opportunityId,
          'postName': postname.text,
          'postingId': postingId
        });
      }

    });
  });
  return candidatesDetails;
};

const setLeverOpportunity = async (teamId) => {
  let url = `${config.leverUrl.replace('teamId', teamId)}`;
  const createResponse = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({})
  });
  validateResponseStatus(createResponse.status);
  const response = await createResponse.text();
  return response;
};

const getLeverOpportunities = async (teamId) => {
  let url = `${config.leverOpportunitiesUrl.replace('teamId', teamId)}`;
  const invitation = await fetch(url, {
    credentials: 'include'
  });
  validateResponseStatus(invitation.status);
  const response = await invitation.json();
  
  return response;
}

const getLeverIntegration = async (teamId) => {
  let url = `${config.leverUrl.replace('teamId', teamId)}`;
  const invitation = await fetch(url, {
    credentials: 'include'
  });
  validateResponseStatus(invitation.status);
  return invitation.status;
}
const _exports = {
  setLeverOpportunity, 
  getLeverOpportunities, 
  getLeverIntegration, 
  deleteLeverOauth, 
  populateCandidatesDetails
}

export default _exports;