import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import ChipInput from 'material-ui-chip-input'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CircularProgress from '@material-ui/core/CircularProgress';
import Yobs from './../../Services/Yobs';
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import utils from './../utils';

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 550,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(0,0,0,0.1)',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '6px'
  },
  inviteButton: {
    float: 'right',
    marginTop: '25px',
    minWidth: '185px'
  },
  emailInput: {
    width: '100%',
    marginBottom: '20px'
  },
  inviteLinkInput: {
    width: '310px',
    marginRight: '5px'
  },
  emailInputHeader: {
    marginBottom: '5px'
  },
  inviteLinkHeader: {
    marginTop: '0px',
    marginBottom: '0px'
  },
  inviteLinkSubHeader: {
    marginTop: '3px',
    marginBottom: '9px',
    color: 'rgba(84, 84, 84) !important'
  },
  copyLink: {
    height: '55px'
  },
  header: {
    marginTop: '0px'
  },
  divider: {
    width: '600px !important',
    position: 'relative',
    right: '50px'
  },
  card: {
    overflow: 'initial'
  },
  chipInput: {
    marginBottom: 20
  },
  shareLink: {
    float: 'right',
    margin: '15px 7px 0px 0px !important',
    padding: '5px 10px 5px 10px !important'
  },
  modalBtn: {
    width: 'calc(50% - 10px)',
    margin: '0px 5px 0px 5px !important',
    display: 'inline'
  },
}));


export default function ShareMeetingModal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [modalTab, setModalTab] =React.useState(1);
  const [accessLevel, setAccessLevel] = React.useState(undefined);
  const [isLoading, setIsLoading] = React.useState(true);
  const [teamMembers, setTeamMembers] = React.useState([]);
  const [toInviteList, setToInviteList] = React.useState([]);
  const [shareEntire, setShareEntire] = React.useState((false));
  const [autocompleteList, setAutocompleteList] = React.useState([]);
  const [memberNameToEmail, setMemberNameToEmail]= React.useState({});

  const handleOpen = async () => {
    setOpen(true);
    const selectedWorkspaceId = (await Yobs.getSettings()).object.selectedTeamId;
    const autocompleteList = [];
    const memberNameToEmail = {};
    const teamMembersData = (await Yobs.getTeamMembers(selectedWorkspaceId)).object.teamMembers
    .filter( (member) => member.email !== props.user.email && member.memberStatus !== "invited")
    .map( (member) => {
      autocompleteList.push(member.memberName);
      autocompleteList.push(member.email);
      memberNameToEmail[member.memberName] = member.email;
      return member.email;
    });

    setAutocompleteList(autocompleteList);
    setMemberNameToEmail(memberNameToEmail);

    setTeamMembers(teamMembersData)
    setIsLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (ev) => {
    setAccessLevel(ev.target.value);
  };
  const ModalBody = () => {
    const handleAddEmailToInviteList = (event, newValue) => {
      if (props.user.teamRole === 'member') {
        handleAddEmailToInviteList_Member(event, newValue);
      } else if (props.user.teamRole === 'admin') {
        handleAddEmailToInviteList_Admin(event, newValue);
      }
    }
    const handleAddEmailToInviteList_Admin = ((event, newValue) => {
      const newlyAddedValue = newValue[newValue.length-1];

      if (memberNameToEmail[newlyAddedValue]) {
        if (!utils.validateEmail(memberNameToEmail[newlyAddedValue])) {
          return;
        }
        if (!newValue.includes(memberNameToEmail[newlyAddedValue])) {
          const newEmailList = [...newValue];
          newEmailList[newEmailList.length-1] = memberNameToEmail[newlyAddedValue];
          setToInviteList(newEmailList)
        }
      } else {
        if (!utils.validateEmail(newlyAddedValue)) {
          return;        
        }
        setToInviteList(newValue)
      }
    });
    const handleAddEmailToInviteList_Member = ((event, newValue) => {
      const newlyAddedValue = newValue[newValue.length-1];

      if (memberNameToEmail[newlyAddedValue]) {
        if (!utils.validateEmail(memberNameToEmail[newlyAddedValue])) {
          return;
        }
        if (!teamMembers.includes(memberNameToEmail[newlyAddedValue])) {
          return;
        }
        if (!newValue.includes(memberNameToEmail[newlyAddedValue])) {
          const newEmailList = [...newValue];
          newEmailList[newEmailList.length-1] = memberNameToEmail[newlyAddedValue];
          setToInviteList(newEmailList)
        }
      } else {
        if (!utils.validateEmail(newlyAddedValue)) {
          return;        
        }
        if (!teamMembers.includes(newlyAddedValue)) {
          return;
        }        
        setToInviteList(newValue)
      }
    });

    const handleChipDelete = (index) => {
      const newList = [...toInviteList];
      newList.splice(index, 1);
      setToInviteList(newList);
    }
    const handleShareSwitch = () => {
      let newList = [];
      if (shareEntire === false) {
        newList = [...toInviteList];
        teamMembers.forEach( (member) => {
          if (!newList.includes(member)) {
            newList.push(member);
          }
        });
      } else {
        newList = [];
        toInviteList.forEach( (email) => {
          if (!teamMembers.includes(email)) {
            newList.push(email);
          }
        })
      }
      setShareEntire(!shareEntire);
      setToInviteList(newList);
    }
    const shareMeeting = async () => {
      if (toInviteList.length) {
        const shareWithNewUser = [];
        const shareWithTeam = [];
        toInviteList.forEach( (email) => {
          if (teamMembers.includes(email)) {
            shareWithTeam.push(email);
          } else {
            shareWithNewUser.push(email);
          }
        });
        const params = {shareWithNewUser, shareWithTeam};
        await Yobs.updateMeeting(props.meetingId, params);
        setToInviteList([]);
        setShareEntire(false);
        handleClose();
        props.onShareComplete();
      }
    }
    if (isLoading) {
      return (
        <div style={modalStyle} className={classes.paper}>
          <Card className={classes.card}>
            <CardContent>        
              <CircularProgress/>
            </CardContent>
          </Card>
        </div>
      )
    }

    return (
      <div style={modalStyle} className={classes.paper}>
        <Card className={classes.card}>
          <CardContent>
              <h3 className={`text-navy ${classes.header}`}> Share meeting
              </h3>
             <Divider className={classes.divider}/>
            <h4>
            {
              (props.user.teamRole === 'admin') && "Share this meeting with a team member or non team member"
            }
            {
              (props.user.teamRole === 'member') && "Share this meeting with a team member"
            }
            </h4>
            <Autocomplete
              multiple
              id="fixed-tags-demo"
              options={autocompleteList}
              value={toInviteList}
              defaultValue={[]}
              freeSolo
              fullWidth
              style={{marginBottom: 20}}
              onChange={handleAddEmailToInviteList}
              renderTags={(value, getTagProps) =>
                value.map((email, index) => (
                  <Chip
                    label={<Typography style={{whiteSpace: 'normal'}}>{email}</Typography>}
                    {...getTagProps({ index })}
                    style={{height:"100%"}}
                    onDelete={() => handleChipDelete(index)}
                  />
                ))
              }
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Enter email addresses"
                />
              )}
            />
            {
              (props.user.teamRole === 'admin') && (
                <React.Fragment>
                  <h4 className={`${classes.inviteLinkHeader}`}>
                    Share with link
                  </h4>
                  <h5 className={classes.inviteLinkSubHeader}>
                    Anyone with link can sign up to your workspace and access this meeting
                  </h5>                    
                  <TextField
                    id="standard-helperText"
                    value={props.meetingInvitationLink}
                    className={classes.inviteLinkInput}
                    disabled
                    variant="outlined"
                  />
                  <Button className={`${classes.copyLink} navy-button`} onClick={() => props.copyMeetingInvitationUrl()}>
                    <FileCopyIcon style={{marginRight: 5}}/>
                    Copy link
                  </Button>
                </React.Fragment>
              )
            }
            <h4 style={{marginBottom: 0}}> 
              Share to your entire workspace 
              <Switch
                checked={shareEntire}
                onChange={handleShareSwitch}
              />              
            </h4>
          </CardContent>
          <CardActions>
            <Typography style={{ flex: 1, padding: '0px 7px' }}>
              <Button size="small" 
                className={`${classes.modalBtn} width-50 navy-outline-button`}
                onClick={handleClose}>
              Cancel
              </Button>
              <Button size="small" 
                className={`${classes.modalBtn} width-50 salmon-button`}
                onClick={shareMeeting}>
                  Share
              </Button>
            </Typography>
          </CardActions>
        </Card>
      </div>
    )
  }

  return (
    <React.Fragment>
      <Button className={`${classes.shareLink} salmon-button`} style={{marginRight: 10}} onClick={handleOpen}> 
        <GroupAddIcon style={{marginRight: '10px'}}/>
        Share
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {ModalBody()}
      </Modal> 
    </React.Fragment>
  );
}
