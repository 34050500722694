module.exports = {
  "getMeetingUrl": "https://api.zoom.yobs.io/v1/api/meetings/meetingId",
  "getMeetingsUrl": "https://api.zoom.yobs.io/v1/api/meetings",
  "getMeetingStatusUrl": "https://api.zoom.yobs.io/v1/api/meetings/meetingId/status",
  "getReportUrl": "https://api.zoom.yobs.io/v1/api/meetings/meetingId/report",
  "getPublicMeetingUrl": "https://api.zoom.yobs.io/v1/api/public-meeting/publicKey",
  "getPublicMeetingCommentsUrl": "https://api.zoom.yobs.io/v1/api/public-meeting/publicKey/comments",
  "getPublicMeetingReportUrl": "https://api.zoom.yobs.io/v1/api/public-meeting/publicKey/report",
  "getUserUrl": "https://api.zoom.yobs.io/v1/api/user",
  "teamsUrl": "https://api.zoom.yobs.io/v1/api/teams",
  "publicTeamsUrl": "https://api.zoom.yobs.io/v1/api/public-teams/teamId",
  "meetingCommentsUrl": "https://api.zoom.yobs.io/v1/api/meeting-comments/meetingUuid",
  "getInvitationUrl": "https://api.zoom.yobs.io/v1/api/teams/teamId/invite-link",
  "sendInvitationEmail": "https://api.zoom.yobs.io/v1/api/teams/teamId/invite-email-address",
  "getTeamMembersUrl": "https://api.zoom.yobs.io/v1/api/team-members/teamId",
  "specificTeamMemberUrl": "https://api.zoom.yobs.io/v1/api/team-members/teamId/member/yobsAccountId",
  "deleteTeamMemberByEmailUrl": "https://api.zoom.yobs.io/v1/api/team-members/teamId/email/emailToDelete",
  "enableProcessingUrl": "https://api.zoom.yobs.io/v1/api/meetings/meetingId/enable-processing",
  "disableProcessingUrl": "https://api.zoom.yobs.io/v1/api/meetings/meetingId/disable-processing",
  "settingsUrl": "https://api.zoom.yobs.io/v1/api/settings",
  "onLogoutUrl": "https://yobstech.com",
  "loginUrl": "https://api.zoom.yobs.io/v1/api/oauth/google",
  "dashboardUrl": "https://zoom.yobs.io/upcoming-calls",
  "logOutUrl": "https://api.zoom.yobs.io/v1/api/user/logout",
  "greenhouseYobsSupportLink": "https://www.yobstech.com/support/connect-greenhouse-within-yobs",
  "greenhouseDefaultLink": "https://app.greenhouse.io/",
  "greenhouseHarvestApi": "https://harvest.greenhouse.io/v1/candidates",
  "greenhouseUrl": "https://api.zoom.yobs.io/v1/api/greenhouse/teamId",
  "greenhouseCandidatesUrl": "https://api.zoom.yobs.io/v1/api/greenhouse/teamId/candidates",
  "resourceCenterLink": "https://yobs.notion.site/yobs/Why-did-the-transcription-of-my-meeting-fail-ec1630f2a95342c5bcacb3bf1310d87a",
  "leverOauthUrl": "https://api.zoom.yobs.io/v1/api/oauth/lever",
  "leverUrl": "https://api.zoom.yobs.io/v1/api/lever/teamId",
  "leverOpportunitiesUrl": "https://api.zoom.yobs.io/v1/api/lever/teamId/opportunities",
  "leverDefaultLink": "https://hire.lever.co/candidates",
  "liveMeetingUrl": "https://zoom.yobs.io/live-meeting/meetingId",
  "meetingPageUrl": "https://zoom.yobs.io/meeting/meetingId?teamId=workspaceId"
};