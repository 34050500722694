import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TabPanel from './../../Components/TabPanel';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Popover from '@material-ui/core/Popover';
import ReactPlayer from 'react-player'
import CommentsContainer from './CommentsContainer';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'

import utils from './../utils';
import Yobs from './../../Services/Yobs';

const useStyles = makeStyles((theme) => ({
  addCommentButton: {
    float: 'right',
    margin: '10px 0px 20px 0px !important'
  },
  addCommentContainer: {
    backgroundColor: 'rgba(30, 30, 30, 1)',
    color: 'white',
    width: 300,
    height: 200,
    borderRadius: '5px'
  },
  commentTextBox: {
    backgroundColor: 'white',
    padding: 0
  },
  commentTextField: {
    borderRadius: '0px !important',
    border: "1px solid rgba(30, 30, 30, 1) !important",
    "&.Mui-focused": {
      border: "1px solid salmon !important",
    }
  },
  tabSubContainer: {
    border: '1px solid rgba(200, 200, 200, 0.3)', 
  },
  contentContainer: {
    boxShadow: 'initial !important',
    backgroundColor: 'white !important',
    color: 'black !important',
  },
  tab: {
    margin: '10px 0px 10px 0px',
    fontWeight: '800'
  },
  mediaContainerHr: {
    borderColor: 'rgba(200,200,200,0.1)',
    bottom: '1px',
    position: 'relative',
    margin: '0px'
  },
  transcriptContainer: {
    maxHeight: '600px',
    overflow: 'scroll'
  },
  transcriptSegment: {
    display: 'flex',
    margin: '0px 0px 20px 0px'
  },
  transcriptIcon: {
    marginRight: '10px',
    position: 'relative',
    bottom: '2px'
  },
  title: {
    fontSize: 14,
  },
  conditionalHide: {
    display: 'none'
  },
  show: {
    display: 'initial'
  }
}));

let player;
let GlobalTimeStamp;
export default function VideoContainer(props) {
  const [tabValue, setTabValue] = React.useState(0);
  const classes = useStyles();

  const ref = (playerInstance) => {
    if (playerInstance) {
      player = playerInstance;
    }
  }
  const skipTo = (ts) => {
    try {
      GlobalTimeStamp = ts;
      currentTimestamp = ts;
      player.seekTo(ts);
    } catch (err) {
      console.log(err);
    }
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  let currentTimestamp = '0';
  const handleAddComment = async (comment) => {
    GlobalTimeStamp = currentTimestamp;
    await Yobs.createMeetingComment(props.meetingId, comment, String(currentTimestamp));
    await props.onAddCommentSuccess();
    skipTo(Math.ceil(GlobalTimeStamp))
  }
  const handlAddCommentClicked = () => {
    GlobalTimeStamp = currentTimestamp; 
    document.getElementsByTagName("video")[0].pause();
  };
  const getCurrentTimestamp = () => {
    if (player) {
      return utils.processTimestamp(player.getCurrentTime());
    } else {
      return utils.processTimestamp(currentTimestamp);
    }
  }
  const AddCommentButton = () => {
    return (
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <div>
            <div onClick={handlAddCommentClicked}>
              <Button className={`salmon-outline-button ${classes.addCommentButton}`} 
                {...bindTrigger(popupState)}>
                + Add comment
             </Button>
           </div>
            <div>
              <Popover
                PaperProps={{ style: { top: 200 }}}
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Box p={2} className={classes.addCommentContainer}>
                  <span style={{marginBottom: 10, display: 'flex'}}>Add Comment - {getCurrentTimestamp()} </span>
                  <Box p={2} className={classes.commentTextBox}>
                     <TextField
                      id="comment-box"
                       fullWidth
                        multiline
                        rows={5}
                        placeholder="Leave comment here... (Press enter to post)"
                        variant="outlined"
                        InputProps={{className: classes.commentTextField}}
                        onKeyPress={(ev) => {
                          if (ev.key === 'Enter') {
                            handleAddComment(ev.target.value);
                            ev.preventDefault();
                            popupState.close();
                          }
                        }}                          
                    />
                  </Box>
                </Box>
              </Popover>
            </div>
          </div>
        )}
      </PopupState>        
    )
  };

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={6} md={6} lg={6} style={{position: 'relative', right: '24px'}}>
          <Paper
            xs={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 240
            }}
          >
          <div className={classes.tabSubContainer}>
            <AppBar position="static"  className={classes.contentContainer}>
              <Tabs value={tabValue} onChange={handleTabChange} aria-label="simple tabs example">
                <Tab label="Video" className={classes.tab}/>
                  {
                    (props.transcript) && (
                      <Tab label="Transcript" className={classes.tab} />
                    )
                  }
              </Tabs>
            </AppBar>
            <hr className={classes.mediaContainerHr}/>
            <div style={{display: 'flex'}}>
              <TabPanel value={0} index={0} className={`${classes[(tabValue === 0) ? 'show' : 'conditionalHide']}`}>
                <ReactPlayer 
                  ref={ref}
                  url={props.videoUrl} controls={true} width={'100%'} height={'auto'} 
                  onPlay={() => {
                    currentTimestamp = player.getCurrentTime();
                    GlobalTimeStamp = player.getCurrentTime();
                  }}
                  onProgress={(progress) => {
                     currentTimestamp = progress.playedSeconds;
                     GlobalTimeStamp = progress.playedSeconds;
                  }}
                  onReady={() => {
                    if (GlobalTimeStamp && player) {
                      let _ts = GlobalTimeStamp;
                      GlobalTimeStamp = undefined;
                      player.seekTo(_ts);
                    }
                  }}
                  /> 
                { !props.publicView && AddCommentButton()}
              </TabPanel>
              <TabPanel value={0} index={0} className={`${classes.transcriptContainer} ${classes[(tabValue === 1) ? 'show' : 'conditionalHide']}`}>
                {
                  props.transcript && props.transcript.map( (segment) => (
                    <div className={classes.transcriptSegment}>
                      <div>
                        <AccountCircleIcon className={`${classes.transcriptIcon} text-navy`}/>
                      </div>
                      <div>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          {segment.speaker} - {segment.timestamp}
                        </Typography>
                        <Typography variant="body2" component="p">
                          {segment.transcript}
                        </Typography>                
                      </div>
                    </div>
                  ))
                }
              </TabPanel>
            </div>
          </div>
          </Paper>
        </Grid>
        <Grid item xs={6} md={6} lg={6} style={{position: 'relative', left: '24px'}}>
          <CommentsContainer skipTo={skipTo} comments={props.comments} workspaceMembers={props.workspaceMembers}/>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
