import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
}));

export default function CandidateModalContent(props) {
  const classes = useStyles();

  if (props.nameOptions.length === 0) {
    return (
      <React.Fragment>
        <h4> No greenhouse candidates found </h4>
      </React.Fragment>
    )
  }

  const headerColorClass = (props.textColor) ? `text-${props.textColor}` : 'text-navy';

  return (
    <React.Fragment>
      <h4 className={`${headerColorClass} ${classes.candidateFullNameInputHeader}`}> Candidate full name:
      </h4>
      <FormControl variant="outlined" fullWidth className={classes.autocomplete}>
        <Autocomplete
          fullWidth
          options={props.nameOptions}
          onChange={props.handleNameChange}
          defaultValue={props.nameOptions[0]}
          className={classes.autocomplete}
          renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
        />
      </FormControl>
      <h4 className={`${headerColorClass} ${classes.jobRoleInputHeader}`}> Job role: </h4>
      <FormControl variant="outlined" fullWidth className={classes.formControl}>
        <Select
          displayEmpty={true}
          value={props.selectedRole}
          onChange={props.handleRoleChange}
        >
          {
            (props.roleOptions.length === 0) && (
              <MenuItem value={0}> None found </MenuItem>
            )
          }
          {
            props.roleOptions.map( (name, index) => <MenuItem value={index} key={index}>{name}</MenuItem> )
          }
        </Select>
      </FormControl>
    </React.Fragment>
  );
}
