import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import Greenhouse from '../../Services/Greenhouse';
import Lever from '../../Services/Lever';

import GreenhouseApiKeyModal from './Greenhouse/GreenhouseApiKeyModal';
import GreenhouseDisconnectModal from './Greenhouse/GreenhouseDisconnectModal';
import LeverDisconnectModal from './Lever/LeverDisconnectModal';
import CircularProgress from '@material-ui/core/CircularProgress';
import IntegrationCard from './IntegrationCard';
import zoomLogo from './../../Assets/zoom-logo.png';
import slackLogo from './../../Assets/slack-logo.png';
import calendarLogo from './../../Assets/calendar-logo.png';
import greenHouseLogo from './../../Assets/greenhouse-logo.jpeg';
import leverLogo from './../../Assets/lever_rgb_mark_standard.png';

import config from '../../config';

const useStyles = makeStyles((theme) => ({
  loader: {
    position: 'relative !important',
    top: '100px !important'
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function IntegrationsPartnersContent(props) {
  const classes = useStyles();
  const { selectedWorkspaceId } = useParams();
  const [meetings, setMeetings] = useState(['loading']);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState(undefined);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isLoading, setIsLoading] = useState(true);
  const [greenhouseOpen, setGreenhouseOpen] = React.useState(false);
  const [greenhouseDisconnectOpen, setGreenhouseDisconnectOpen] = React.useState(false);
  const [leverDisconnectOpen, setLeverDisconnectOpen] = React.useState(false);
  
  const [integrations, setIntegrations] = React.useState(
    {
      greenhouse: undefined, 
      lever: undefined
    }
  );
  

  useEffect( () => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const newState = Object.assign({}, integrations);
    const getGreenhouseIntegrationResponse = await Greenhouse.getGreenhouseIntegration(selectedWorkspaceId);
    if (getGreenhouseIntegrationResponse === 400) {
      newState.greenhouse = 'disconnected'
    } else if (getGreenhouseIntegrationResponse === 200) {
      newState.greenhouse = 'connected'
    }

    const getLeverIntegrationResponse = await Lever.getLeverIntegration(selectedWorkspaceId);
    if (getLeverIntegrationResponse === 400) {
      newState.lever = 'disconnected'
    } else if (getLeverIntegrationResponse === 200) {
      newState.lever = 'connected'
    }
    setIntegrations(newState);
    setIsLoading(false);
    
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const leverConnected = params.get('leverConnected');
    
    if( leverConnected ) {
      if( leverConnected === 'success' ) {
        setSnackbarSeverity('success');
        setSnackbarMsg('Lever connected successfully');
      }
      else if (leverConnected === 'failure') {
        setSnackbarSeverity('error');
        setSnackbarMsg('Lever connection failed');
      }
    }
  };

  const handleSnackbarClose = (event, reason) => {
    setSnackbarMsg(undefined);
  };

  const handleGreenhouseApiKeySubmit = async (apiKey) => {
    if (apiKey.length) {
      setIsLoading(true);
      const createResponse = await Greenhouse.createGreenhouseApiKey(selectedWorkspaceId, apiKey);
      if (createResponse === 401) {
        setGreenhouseOpen(false);
        setSnackbarSeverity('error');
        setSnackbarMsg('Invalid API Key');
        setIsLoading(false);
        return;
      } else if (createResponse === 403) {
        setGreenhouseOpen(false);
        setSnackbarSeverity('error');
        setSnackbarMsg('Missing the required permissions in this API Key');
        setIsLoading(false);
        return;
      }
      await fetchData();
      setGreenhouseOpen(false);
      setSnackbarSeverity('success');
      setSnackbarMsg('Greenhouse connected successfully')
      setIsLoading(false);
    }
  };
  const handleGreenhouseDisconnect = async () => {
    setIsLoading(true);
    await Greenhouse.deleteGreenhouseApiKey(selectedWorkspaceId);
    await fetchData();
    setGreenhouseDisconnectOpen(false);
    setSnackbarMsg('Greenhouse disconnected successfully')
    setIsLoading(false);
  };

  const handleLeverOauthConnect = () => {

    const authURI = `${config.leverOauthUrl}?teamId=${selectedWorkspaceId}`;
    const encodedAuthURI = encodeURI(authURI);
    window.location.replace(encodedAuthURI);
  };

  const handleLeverOauthDisconnect = async () => {
    setIsLoading(true);
    const newState = Object.assign({}, integrations);
    const deleteResponse = await Lever.deleteLeverOauth(selectedWorkspaceId);
    
    if(deleteResponse) {
      newState.lever = 'disconnected';
    } else {
      newState.lever = 'connected';
    }
    setIntegrations(newState);
    setLeverDisconnectOpen(false);
    setSnackbarMsg('Lever disconnected successfully');
    setIsLoading(false);
  }

  if (isLoading) {
    return (
      <div>
        <CircularProgress className={classes.loader}/>
      </div>
    )
  }
  
  return (
    <div>
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={snackbarMsg}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
      message={snackbarMsg}
    >
      <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
        {snackbarMsg}
      </Alert>
    </Snackbar>
    {
      integrations.greenhouse === 'connected' ? (
        <GreenhouseDisconnectModal 
          open={greenhouseDisconnectOpen}
          onSubmit={handleGreenhouseDisconnect}
          isLoading={isLoading}
          handleOpen={() => setGreenhouseDisconnectOpen(true)} 
          handleClose={() => setGreenhouseDisconnectOpen(false)}
        />
      ) : (
        <GreenhouseApiKeyModal 
          open={greenhouseOpen}
          onSubmit={handleGreenhouseApiKeySubmit}
          isLoading={isLoading}
          handleOpen={() => setGreenhouseOpen(true)} 
          handleClose={() => setGreenhouseOpen(false)}
        />
      )
    }
    {
       (integrations.lever === 'connected') && (
        <LeverDisconnectModal 
          open={leverDisconnectOpen}
          onSubmit={handleLeverOauthDisconnect}
          isLoading={isLoading}
          handleOpen={() => setLeverDisconnectOpen(true)} 
          handleClose={() => setLeverDisconnectOpen(false)}
        />
       )
    }
    <Grid container spacing={3} className="main-content main-content-container">
      <Grid item xs={6} md={6} lg={6}>
        <h1 className="text-navy"> Integrations </h1>
      </Grid>
      <Grid item xs={12} md={12} lg={12} className="main-content-container">
        <Paper
          className="background-initial"
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 240,
          }}
        >
          <IntegrationCard
            title={'Google Calendar'}
            buttonText={'Disconnect'}
            icon={calendarLogo}
            disabled={true}
          />
          <IntegrationCard
            title={'Zoom'}
            buttonText={'Disconnect'}
            icon={zoomLogo}
            disabled={true}
          />
          {
            integrations.greenhouse === 'connected' ? (
              <IntegrationCard
                title={'Greenhouse'}
                buttonText={'Disconnect'}
                icon={greenHouseLogo}
                onClick={() => setGreenhouseDisconnectOpen(!greenhouseDisconnectOpen)}
              />
            ) : (
              <IntegrationCard
                title={'Greenhouse'}
                buttonText={'Connect'}
                icon={greenHouseLogo}
                onClick={() => setGreenhouseOpen(!greenhouseOpen)}
              />            
            )
          }
          {
            integrations.lever === 'connected' ? (
              <IntegrationCard
                title={'Lever'}
                buttonText={'Disconnect'}
                icon={leverLogo}
                onClick={() => setLeverDisconnectOpen(!leverDisconnectOpen)}
              />
            ) : (
              <IntegrationCard
                title={'Lever'}
                buttonText={'Connect'}
                icon={leverLogo}
                onClick={handleLeverOauthConnect}
              />            
            )
          }
          <IntegrationCard
            title={'Slack'}
            buttonText={'Coming soon'}
            icon={slackLogo}
            disabled={true}
          />
        </Paper>
      </Grid>
    </Grid>
    </div>
  );
}

export default function IntegrationsPartners() {
  return <IntegrationsPartnersContent />;
}