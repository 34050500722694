import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { MentionsInput, Mention } from 'react-mentions'


const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px 0px 0px 0px',
  },
  textAreaContainer: {
    resize: 'initial'
  },
  emojiButton: {
    margin: 2,
    borderRadius: '25px',
    border: '2px solid rgba(0,0,0,0.2)',
    fontSize: '10px',
    minWidth: 'initial',
    width: '50px',
    float: 'right'
  },
  mentions: {
    height: '100px'
  },
  timer: {
    textAlign: 'center',
    color: 'rgba(97, 183, 231, 1)',
    fontWeight: 'bold',
    paddingTop: 10
  },
  divider: {
    width: 'calc(100% + 50px)',
    position: 'relative',
    right: 25,
    borderColor: 'rgba(0,0,0,0.1)',
    backgroundColor: 'rgba(0,0,0,0.1)',
    height: '2px',
    border: 'none',
    marginBottom: 20
  }
}));

export default function AddComment(props) {
  const classes = useStyles();
  const [commentText, setCommentText] = useState('');
  const [mentions, setMentions] = useState([]);

  const handleTextChange = (ev) => {
    setCommentText(ev.target.value);
  };

  const mentionList = props.workspaceMembers
  .map( (member,index) => 
    Object.assign({accountId: member.memberClientDashboardAccountId, display: member.memberName, name: member.memberName})
  ).concat(props.workspaceMembers.map( (member) => 
    Object.assign({accountId: member.memberClientDashboardAccountId, display: member.email, name: member.memberName}))
  )
  .map( (i, index) => Object.assign(i, {id:index}));

  const onAddMention = (id, display, startPos, endPos) => {
    const user = mentionList.filter( (member) => member.id === id)[0];
    const newMentionsState = mentions.slice();
    newMentionsState.push(user);
    const newCommentState = [commentText.slice(0, startPos), `@${user.name}`, commentText.slice(endPos)].join('');

    setCommentText(newCommentState);
    setMentions(newMentionsState);
  };

  const addEmoji = (emoji) => {
    const newCommentState = commentText + " " + emoji;
    setCommentText(newCommentState);
  };

  const getRawComment = () => {
    let rawComment = commentText.slice();
    mentions.forEach( (user) => {
      rawComment = rawComment.replace(user.name, user.accountId)
    });
    return rawComment;
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={5}>
        <Grid item xs={3} md={3} lg={3}>
          <div className={classes.timer}>
            {props.meetingTimer}
          </div>
        </Grid>
        <Grid item xs={9} md={9} lg={9}>
            <div className={classes.textAreaContainer}>
            <MentionsInput 
              value={commentText} 
              onChange={handleTextChange}
              className={classes.mentions}
              id={'mentionsComponent'}
              placeholder="Type to add notes... (Press enter to post)"
              onKeyPress={async (ev) => {
                if (ev.key === 'Enter') {
                  ev.preventDefault();
                  setCommentText('');
                  setMentions([]);
                  await props.handleAddComment(getRawComment());
                }
              }}
            >
              <Mention
                trigger="@"
                data={mentionList}
                markup="@[__display__](user:__id__)"
                onAdd={onAddMention}
                appendSpaceOnAdd={true}
                renderSuggestion={(suggestion, search, highlightedDisplay) => {
                  return (
                    <div className="user">{highlightedDisplay}</div>
                  )
                }}
              />
            </MentionsInput>            
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={10}>
        <Grid item xs={4} md={4} lg={4}>
        </Grid>
        <Grid item xs={8} md={8} lg={8}>
          <div className={classes.emojiContainer}>
            <Button className={classes.emojiButton} onClick={() => addEmoji('👎')}>👎</Button>       
            <Button className={classes.emojiButton} onClick={() => addEmoji('👀')}>👀</Button>
            <Button className={classes.emojiButton} onClick={() => addEmoji('🚩')}>🚩</Button>       
            <Button className={classes.emojiButton} onClick={() => addEmoji('👍')}>👍</Button>
          </div>
        </Grid>
      </Grid>
      <hr className={classes.divider} />
    </div>
  );
};

