import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
  button: {
    float: 'right',
    margin: '20px'
  },
  saveButton: {
    width: '100px'
  },
  meetingSettingsContainer: {
    width: '100%'
  }
}));

export default function MeetingSettings(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(1);
  const [stepData, setStepData] = React.useState(['', '', '']);
  const defaultStepSelection = ['hiring', 'Sales', 'Sales Manager'];
  const handleCheckboxChange = (stepNumber, checked, value) => {
    if (checked) {
      const newStepData = [... stepData];
      newStepData[stepNumber-1] = value;
      setStepData(newStepData);
    } else {
      const newStepData = [... stepData];
      newStepData[stepNumber-1] = '';
      setStepData(newStepData);
    }
    if (stepNumber+1 > activeStep) {
      setActiveStep(stepNumber+1);
    }
  };

  const step1 = {
    stepNumber: 1,
    header: 'What kind of meeting is this?',
    checkBoxes: [{
      title: 'Interview',
      value: 'hiring',
    },{
      title: '1:1',
      value: 'talentDevelopment',
    }]
  };
  const step2 = {
    stepNumber: 2,
    header: 'What function is this person in?',
    checkBoxes: [{
      title: 'Sales',
      value: 'Sales'
    },{
      title: 'Engineering',
      value: 'Engineering'
    },{
      title: 'Design',
      value: 'Design'
    },{
      title: 'Product',
      value: 'Product'
    },{
      title: 'Operations',
      value: 'Operations'
    },{
      title: 'Marketing',
      value: 'Marketing'
    },{
      title: 'Management',
      value: 'Management'
    },{
      title: 'Other',
      value: 'Other'
    },]
  };
  const getTitle = (value) => {
    for (const c of step2.checkBoxes) {
      if (c.value === value) {
        return c.title
      }
    }
    return ''
  }
  const step3 = {
    stepNumber: 3,
    header: 'What ROLE_NAME role are you looking to fill?',
    checkBoxes: [{
      title: "Business Analyst",
      value: "Business Analyst",
      functionTitle: "Other",
      previewUrl: "",
    },{
      title: "Data Analyst",
      value: "Data Analyst",
      functionTitle: "Other",
      previewUrl: "",
    },{
      title: "Project Manager",
      value: "Project Manager",
      functionTitle: "Other",
      previewUrl: "",
    },{
      title: "Attorney",
      value: "Attorney",
      functionTitle: "Other",
      previewUrl: "",
    },{
      title: "CEO",
      value: "CEO",
      functionTitle: "Management",
      previewUrl: "",
    },{
      title: "CFO",
      value: "CFO",
      functionTitle: "Management",
      previewUrl: "",
    },{
      title: "CMO",
      value: "CMO",
      functionTitle: "Management",
      previewUrl: "",
    },{
      title: "COO",
      value: "COO",
      functionTitle: "Management",
      previewUrl: "",
    },{
      title: "CTO",
      value: "CTO",
      functionTitle: "Management",
      previewUrl: "",
    },{
      title: "Growth Hacker",
      value: "Growth Hacker",
      functionTitle: "Marketing",
      previewUrl: "",
    },{
      title: "Marketing Manager",
      value: "Marketing Manager",
      functionTitle: "Marketing",
      previewUrl: "",
    },{
      title: "Content Creator",
      value: "Content Creator",
      functionTitle: "Marketing",
      previewUrl: "",
    },{
      title: "Social Media Manager",
      value: "Social Media Manager",
      functionTitle: "Marketing",
      previewUrl: "",
    },{
      title: "Copywriter",
      value: "Copywriter",
      functionTitle: "Marketing",
      previewUrl: "",
    },{
      title: "Digital Marketing Manager",
      value: "Digital Marketing Manager",
      functionTitle: "Marketing",
      previewUrl: "",
    },{
      title: "Product Marketing Manager",
      value: "Product Marketing Manager",
      functionTitle: "Marketing",
      previewUrl: "",
    },{
      title: "Finance/Accounting",
      value: "Finance/Accounting",
      functionTitle: "Operations",
      previewUrl: "",
    },{
      title: "H.R.",
      value: "H.R.",
      functionTitle: "Operations",
      previewUrl: "",
    },{
      title: "Office Manager",
      value: "Office Manager",
      functionTitle: "Operations",
      previewUrl: "",
    },{
      title: "Recruiter",
      value: "Recruiter",
      functionTitle: "Operations",
      previewUrl: "",
    },{
      title: "Customer Service",
      value: "Customer Service",
      functionTitle: "Operations",
      previewUrl: "",
    },{
      title: "Operations Manager",
      value: "Operations Manager",
      functionTitle: "Operations",
      previewUrl: "",
    },{
      title: "Chief of Staff",
      value: "Chief of Staff",
      functionTitle: "Operations",
      previewUrl: "",
    },{
      title: "Product Manager",
      value: "Product Manager",
      functionTitle: "Product",
      previewUrl: "",
    },{
      title: "User Researcher",
      value: "User Researcher",
      functionTitle: "Design",
      previewUrl: "",
    },{
      title: "Visual Designer",
      value: "Visual Designer",
      functionTitle: "Design",
      previewUrl: "",
    },{
      title: "Creative Director",
      value: "Creative Director",
      functionTitle: "Design",
      previewUrl: "",
    },{
      title: "Product Designer",
      value: "Product Designer",
      functionTitle: "Design",
      previewUrl: "",
    },{
      title: "Graphic Designer",
      value: "Graphic Designer",
      functionTitle: "Design",
      previewUrl: "",
    },{
      title: "Design Manager",
      value: "Design Manager",
      functionTitle: "Design",
      previewUrl: "",
    },{
      title: "Software Engineer",
      value: "Software Engineer",
      functionTitle: "Engineering",
      previewUrl: "",
    },{
      title: "DevOps",
      value: "DevOps",
      functionTitle: "Engineering",
      previewUrl: "",
    },{
      title: "Hardware Engineer",
      value: "Hardware Engineer",
      functionTitle: "Engineering",
      previewUrl: "",
    },{
      title: "Mobile Engineer",
      value: "Mobile Engineer",
      functionTitle: "Engineering",
      previewUrl: "",
    },{
      title: "Frontend Engineer",
      value: "Frontend Engineer",
      functionTitle: "Engineering",
      previewUrl: "",
    },{
      title: "Machine Learning Engineer",
      value: "Machine Learning Engineer",
      functionTitle: "Engineering",
      previewUrl: "",
    },{
      title: "Android Developer",
      value: "Android Developer",
      functionTitle: "Engineering",
      previewUrl: "",
    },{
      title: "Engineering Manager",
      value: "Engineering Manager",
      functionTitle: "Engineering",
      previewUrl: "",
    },{
      title: "QA Engineer",
      value: "QA Engineer",
      functionTitle: "Engineering",
      previewUrl: "",
    },{
      title: "Mechanical Engineer",
      value: "Mechanical Engineer",
      functionTitle: "Engineering",
      previewUrl: "",
    },{
      title: "Full-Stack Engineer",
      value: "Full-Stack Engineer",
      functionTitle: "Engineering",
      previewUrl: "",
    },{
      title: "Data Engineer",
      value: "Data Engineer",
      functionTitle: "Engineering",
      previewUrl: "",
    },{
      title: "Security Engineer",
      value: "Security Engineer",
      functionTitle: "Engineering",
      previewUrl: "",
    },{
      title: "Data Scientist",
      value: "Data Scientist",
      functionTitle: "Engineering",
      previewUrl: "",
    },{
      title: "Software Architect",
      value: "Software Architect",
      functionTitle: "Engineering",
      previewUrl: "",
    },{
      title: "Systems Engineer",
      value: "Systems Engineer",
      functionTitle: "Engineering",
      previewUrl: "",
    },{
      title: "Backend Engineer",
      value: "Backend Engineer",
      functionTitle: "Engineering",
      previewUrl: "",
    },{
      title: "Embedded Engineer",
      value: "Embedded Engineer",
      functionTitle: "Engineering",
      previewUrl: "",
    },{
      title: "iOS Developer",
      value: "iOS Developer",
      functionTitle: "Engineering",
      previewUrl: "",
    },{
      title: "Sales Development Rep",
      value: "Sales Development Rep",
      functionTitle: "Sales",
      previewUrl: "",
    },{
      title: "Sales Manager",
      value: "Sales Manager",
      functionTitle: "Sales",
      previewUrl: "",
    },{
      title: "Business Development Manager",
      value: "Business Development Manager",
      functionTitle: "Sales",
      previewUrl: "",
    },{
      title: "Account Executive",
      value: "Account Executive",
      functionTitle: "Sales",
      previewUrl: "",
    },{
      title: "Account Manager",
      value: "Account Manager",
      functionTitle: "Sales",
      previewUrl: "Sales",
    },{
      title: "Customer Success Manager",
      value: "Customer Success Manager",
      functionTitle: "Sales",
      previewUrl: "",
    },{
      title: "Business Development",
      value: "Business Development",
      functionTitle: "Sales",
      previewUrl: ""
    }]
  }
  function createStepComponent(stepNumber, header, checkboxes) {
    if (stepNumber === 3) {
      const checkboxesToRender = checkboxes.filter( (data) => data.functionTitle === stepData[1]);
      if (checkboxesToRender.length) {
        return (
          <React.Fragment>
            <h4> {header} </h4>
            <FormGroup row>
              { checkboxesToRender.map( (checkbox, i) => (
                  <React.Fragment key={i}>
                    <FormControlLabel
                      control={<Checkbox 
                          checked={stepData[stepNumber-1] === checkbox.value} 
                          onChange={(ev) => handleCheckboxChange(stepNumber, ev.target.checked, checkbox.value)} 
                          name={checkbox.title}
                          labelStyle={{color: 'salmon'}}
                      />}
                      label={checkbox.title}
                    />
                  </React.Fragment>
              ))}
            </FormGroup>      
          </React.Fragment>        
        )
      }
      return;
    }

    return (
      <React.Fragment>
        <h4> {header} </h4>
        <FormGroup row>
          { checkboxes.map( (checkbox, i) => (
              <React.Fragment key={i}>
                <FormControlLabel
                  control={<Checkbox 
                      checked={stepData[stepNumber-1] === checkbox.value} 
                      onChange={(ev) => handleCheckboxChange(stepNumber, ev.target.checked, checkbox.value)} 
                      name={checkbox.title}
                      labelStyle={{color: 'salmon'}}
                  />}
                  label={checkbox.title}
                />
              </React.Fragment>
          ))}
        </FormGroup>      
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <div className={classes.meetingSettingsContainer}>
        { activeStep >= 1 && createStepComponent(step1.stepNumber, step1.header, step1.checkBoxes) }
        { activeStep >= 2 &&  createStepComponent(step2.stepNumber, step2.header, step2.checkBoxes) }
        { activeStep >= 3 && createStepComponent(step3.stepNumber, 
          (getTitle(stepData[1]) === "Other") ?
            step3.header.replace('ROLE_NAME',"") :
            step3.header.replace('ROLE_NAME',getTitle(stepData[1])), step3.checkBoxes
        )}
        <Button className={`${classes.button} ${classes.saveButton} salmon-button`} onClick={() => props.enableYobsProcessing(props.meetingUUId, props.index, stepData)}>
          Save
        </Button>
        <Button className={`${classes.button} text-underline`} onClick={() => props.enableYobsProcessing(props.meetingUUId, props.index, defaultStepSelection)}>
          Skip setup
        </Button>
      </div>
    </React.Fragment>
  );
}
