import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import Grid from '@material-ui/core/Grid';
import GetAppIcon from '@material-ui/icons/GetApp';

import config from '../../config';


const useStyles = makeStyles((theme) => ({
  contentContainer: {
    boxShadow: 'initial !important',
    backgroundColor: 'white !important',
    color: 'black !important',
  },
  reportHeader: {
    fontSize: '1rem',
    fontWeight: 'initial',
    margin: '20px 25px 20px 25px',
    fontWeight: '800',
    lineHeight: '1.75',
    whiteSpace: 'normal',
    letterSpacing: '0.02857em'
  },
  downloadLink: {
    float: 'right',
    border: '1px solid salmon',
    width: '200px',
    margin: '13px 10px 10px 10px',
    padding: '10px 20px 10px 20px !important'
  },
  mediaContainerHr: {
    borderColor: 'rgba(200,200,200,0.1)',
    bottom: '1px',
    position: 'relative',
    margin: '0px'
  },
  innerContentContainer: {
    height: 'auto',
    overflow: 'scroll',
    padding: '10px 225px 10px 225px',
    maxHeight: '600px'
  },
  pdfLoaderStyle: {
    height: '100px',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
}));

export default function ReportContainer(props) {
  const classes = useStyles();
  const [numPages, setNumPages] = React.useState(null);

  return (
    <AppBar position="static"  className={classes.contentContainer} 
      style={{width:'calc(100% + 48px', position: 'relative', right: '24px', border: '1px solid rgba(200, 200, 200, 0.3)'}}
    >
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <h2 className={classes.reportHeader}> Yobs Report </h2>
        </Grid>
        <Grid item xs={6}>
          <a href={`${config.getReportUrl.replace('meetingId', props.meetingId)}`} target="_blank">
          {
            !props.publicView && (
              <Button className={`${classes.downloadLink} salmon-button`}> 
                <GetAppIcon style={{marginRight: '10px'}}/>
                Download report 
              </Button>
            )
          }
          </a>
        </Grid>
      </Grid>
      <hr className={classes.mediaContainerHr}/>
      <div className={classes.innerContentContainer}>
        <Document file={props.pdfReport} 
          loading={(
            <div className={classes.pdfLoaderStyle}>
              <div>
                <CircularProgress />
                <span>Downloading your document... (might take a while)</span>
              </div>
            </div>
          )}              
          onLoadSuccess={({ numPages })=>setNumPages(numPages)}>
          {Array.apply(null, Array(numPages))
          .map((x, i)=>i+1)
          .map((page, i) => <Page key={i} pageNumber={page}/>)}
        </Document>
      </div>
    </AppBar>      
  );
};

